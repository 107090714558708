import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './FourArticles.scss'
import {AddFourArticles} from '../Ads'
import Loader from '../Loader';
import slug from "slug";

function FourArticles(props) {
    const name = "FourArticles";

    // Articles data storage
    const [data, updateData] = useState(false);

    // Static url to get data from
    const dataUrl = `${props.data}/articles-list/popular/${props.type}`

    // Get and update data
    useEffect(() => {
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
            })
            .catch(err => { throw err });
    }, [dataUrl]);


    return (
        data ? (
            <section id="four-articles">
                <Link to={`/article-list/${props.type}`}>
                    <h3 className="section-title">{data[0].category}</h3>
                </Link>
                <div className="four-articles-wrapper">
                    {data.map((item, index) => {
                        // Check if image_small exists than check for standard one else set placeholder
                        let image;
                        if (JSON.parse(item.data).image_small.src.length > 0) {
                            image = JSON.parse(item.data).image_small.src
                        } else if (JSON.parse(item.data).image.src.length > 0) {
                            image = JSON.parse(item.data).image.src
                        } else {
                            image = "storage/placeholder.png";
                        }
                        return (
                            index < 3 ? (
                            <div key={index}>
                                <Link to={`article/${item.id}${item.title && item.title.length ? '-'+slug(item.title) : ''}`}>
                                    <div className="textBox">
                                        <div className="title">{item.title}</div>
                                        <div className="author">{item.author}</div>
                                        <div className="media"></div>
                                    </div>
                                </Link>
                                <Link to={`article/${item.id}${item.title && item.title.length ? '-'+slug(item.title) : ''}`}>
                                    <div>
                                        <div className="image" style={{ backgroundImage: `url(${props.data}/${image})` }}></div>
                                    </div>
                                </Link>
                            </div>
                            ) : (
                                <AddFourArticles key={index} />
                            )
                        )
                    })}
                </div>
            </section>) : <Loader name={name} />
    );
}

export default FourArticles;