import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import '../ArticleList/ArticleList.scss'
import Loader from '../../Components/Loader'

export default function TagList() {

    let { id } = useParams();

    let { url } = useRouteMatch();

    const name = "TagList"

    // Tags data storage
    const [data, updateData] = useState(false);

    // Static url to get data from
    const dataUrl = `${window.globalUrl}/articles-list/list-by-tag/${id}`;

    console.log("Tag list url: ", dataUrl);


    // Don't map 1st item for desktop
    let nextData = data ? data.slice(1) : [];

    // Get Data
    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
                console.log(name + " successfuly loaded");
            })
            .catch(err => { throw err });
    }, [url]);

    let bigImage, bigImageItem;

    if (data && data.length > 0) {
        bigImageItem = JSON.parse(data[0].data)
        if (bigImageItem.image.src.length > 0) {
            bigImage = bigImageItem.image.src;
        } else if (bigImageItem.image_small.src.length > 0) {
            bigImage = bigImageItem.image_small.src;
        } else {
            bigImage = "storage/placeholder.png";
        }
    }

    return (
        <main>
            {data && data.length > 0 ? (
                <div id="article-list">
                    <div className="main-article">
                        <Link to={`../article/${data[0].id}`}>
                            <div className="image" style={{ backgroundImage: `url(${window.globalUrl}/${bigImage})` }}></div>
                            <div className="title">{data[0].title}</div>
                            <div className="lead">{data[0].excerpt}</div>
                            <div className="author">{data[0].author}</div>
                        </Link>
                    </div>
                    <div className="articles-wrapper">
                        {nextData.map((item, index) => {

                            // Check if image_small exists then check for standard one else set placeholder

                            let image;
                            const itemData = JSON.parse(item.data);

                            if (itemData.image_small.src.length > 0) {
                                image = itemData.image_small.src;
                            } else if (itemData.image.src.length > 0) {
                                image = itemData.image.src;
                            } else {
                                image = "storage/placeholder.png";
                            }

                            return (
                                <div key={index} className="article">
                                    <Link to={`../article/${item.id}`}>
                                        <div className="image"
                                            style={{ backgroundImage: `url(${window.globalUrl}/${image})` }}>
                                        </div>
                                        <div>
                                            <div className="title">{item.title}</div>
                                            <div className="lead">{item.excerpt}</div>
                                            <div className="author">{item.author}</div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        }
                        )}
                    </div>
                    {/* <div>
                        <button>Więcej artykułów</button>
                    </div> */}
                </div>
            ) : <Loader name={name} />
            }
        </main>
    )
}
