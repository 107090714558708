import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import './VideoSection.scss';
import VideoPlayer from '../VideoPlayer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { mobile } from '../Helpers';
import Loader from '../Loader';
import slug from "slug";

function VideoSection(props) {

    // Video data storage
    const [data, updateData] = useState(false);

    // Static url to get data from
    const dataUrl = `${props.data}/video`;

    // Get and update data
    useEffect(() => {
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
            })
            .catch(err => { throw err });
    }, []);

    // Don't map 1st item for desktop
    let subdata = data ? data.slice(1,5) : [];

    const prevSlide = () => document.querySelector("#video-carousel .owl-prev").click();
    const nextSlide = () => document.querySelector("#video-carousel .owl-next").click();
    

    return (
        data ? (
            <section id="video-section">
                <h3 className="section-title">Wideo</h3>
                {
                    mobile() ? (
                        // MOBILE VERSION
                        <div className="video-section-wrapper">
                            <OwlCarousel id="video-carousel" className="owl-theme video-carousel" loop margin={10} dots={false} items={1}>
                                {data.map(function (item, index) {
                                    return (
                                        <div key={index}>
                                            <VideoPlayer source={item.embed} poster={props.data + "/storage/dharsa-video/" + item.image} youtube_id={item.youtube_id}/>
                                            <a href={`/video/${item.id}`}>
                                                {item.title ? <div className="title">{item.title}</div> : null}
                                                {item.lead ? <div className="lead">{item.lead}</div> : null}
                                            </a>
                                        </div>
                                    )
                                })}
                            </OwlCarousel>
                            <button className="carousel-button carousel-prev" onClick={prevSlide}></button>
                            <button className="carousel-button carousel-next" onClick={nextSlide}></button>
                        </div>
                    ) : (
                            // DESKTOP VERSION
                            <div className="wrapper">
                                <div className="main-box">
                                    <VideoPlayer source={data[0].embed} poster={props.data + "/storage/dharsa-video/" + data[0].image} youtube_id={data[0].youtube_id}/>
                                    {console.log(data[0].embed , "embed")}
                                    <Link to={`/video/${data[0].id}${data[0].title && data[0].title.length ? '-'+slug(data[0].title) : ''}`}>
                                        <div className="title">{data[0].title}</div>
                                        <div className="lead">{data[0].description}</div>
                                        <div className="author">{data[0].author}</div>
                                    </Link>
                                </div>
                                <div className="sub-box">
                                    {subdata.map(function (item, index) {
                                        return (
                                            <Link key={index} to={`/video/${item.id}${item.title && item.title.length ? '-'+slug(item.title) : ''}`}>
                                                <div>
                                                    <div>
                                                        <div className="player-small-sign"></div>
                                                    </div>
                                                    <div>
                                                        <div className="title">{item.title}</div>
                                                        <div className="author">{item.author}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                    <div></div>
                                </div>
                            </div>
                        )
                }
            </section>
        ) : (<Loader error="VideoSection" />)
    );
}
export default VideoSection;