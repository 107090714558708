import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useParams, useRouteMatch } from "react-router-dom";
import ArticleTitle from '../../Components/ArticleTitle';
import AuthorBox from '../../Components/AuthorBox';
import ArticleContent from '../../Components/ArticleContent';
import './TextPage.scss';
import { mobile } from '../../Components/Helpers';
import { minHeight } from '../../Components/Helpers';
import Loader from '../../Components/Loader'
import about_img from "../../assets/img/about.png";
import image1 from "../../assets/img/authors/DUBIEL.jpg";
import image2 from "../../assets/img/authors/CICHY.jpg";
import image3 from "../../assets/img/authors/KUCHARSKI.jpg";
import image10 from "../../assets/img/authors/CIUPKA.jpg";
import image4 from "../../assets/img/authors/STEFANSKI.jpg";
import image5 from "../../assets/img/authors/KOLINEK-SIECHOWICZ.jpg";
import image11 from "../../assets/img/authors/MICIAL.jpg";
import image6 from "../../assets/img/authors/SUPRYNOWICZ.jpg";
import image7 from "../../assets/img/authors/MARCZYNSKI.jpg";
import image8 from "../../assets/img/authors/KNAP.jpg";
import image9 from "../../assets/img/authors/CYRANKIEWICZ.jpg";
import pwm from "../../assets/img/pwm-edition.png";
import virtualo from "../../assets/img/virtualo.png";
import publio from "../../assets/img/publio.png";
import kiosk from "../../assets/img/e-kiosk.png";
import nexto from "../../assets/img/nexto.png";

function TextPage(props) {

	window.scrollTo(0, 0);

	let { id } = useParams();

	let { url } = useRouteMatch();

	const name = "TextPage"

	// Articles data storage
	const [data, updateData] = useState(false);
	const [personsData, updatePersonsData] = useState([])
	const [personsData2, updatePersonsData2] = useState([])
	/*const [counter, updateCounter] = useState(0)*/
	const [dataAboutLeftColumn, updateDataAboutLeftColumn] = useState(false)
	const [dataAboutRightColumn, updateDataAboutRightColumn] = useState(false)
	const [isFetching, updateIsFetching] =useState(false)

	const mainUrl = props.data

	// Static url to get data from
	const dataUrl = `${props.data}/api/site/page/${props.dataId}`;
	const dataAboutUrl = `${props.data}/api/site/page/`;

	const firstPersonData = (object) => {

		let oldPersonsData = [];

		object.forEach((item) => {
			let person = JSON.parse(item.data)
			oldPersonsData.push(person)
		})

		updatePersonsData(oldPersonsData)
	}

	// Get Data
	useEffect(() => {
		window.scrollTo(0, 0);
		if(props.dataId !== 2) {
			fetch(dataUrl)
				.then(res => res.json())
				.then((out) => {
					updateData(out);
					minHeight();
				})
				.catch(err => { throw err });
			if(props.dataId === 5) {
				fetch(dataAboutUrl + "6")
					.then(res => res.json())
					.then((out) => {
						updateDataAboutLeftColumn(out);
					})
					.catch(err => { throw err });
				fetch(dataAboutUrl + "7")
					.then(res => res.json())
					.then((out) => {
						updateDataAboutRightColumn(out);
					})
					.catch(err => { throw err });
			}
		} else {
			fetch(dataUrl)
				.then(res => res.json())
				.then((out) => {
					updateData(out);
				})
				.catch(err => { throw err });
			fetch(`${props.data}/api/site/page/parent/3`)
				.then(res => res.json())
				.then((out) => {
					firstPersonData(out);
				})
				.catch(err => { throw err });
		}
	}, [url]);

	useEffect(() => {
		let oldPersonsData2 = []
		let counter = 0

		personsData.forEach((person) => {
			fetch(`${dataAboutUrl}${person.defaults.id}`)
				.then(res => res.json())
				.then((out) => {
					counter++;
					oldPersonsData2.push(out)
					if(counter === personsData.length) {
						concatPersonData(oldPersonsData2);
						updateIsFetching(true)
					}
				})
				.catch(err => { throw err });
		})
		updatePersonsData2(oldPersonsData2)

	},[personsData])

	const concatPersonData = (data) => {
		let concatedData = []

		personsData.forEach((person) => {
			data.forEach((data) => {
				if (data.id === person.defaults.id) {
					let tempData = [];
					tempData.push(person)
					tempData.push(data)
					concatedData.push(tempData)
				}
			})
		})

		updatePersonsData2(concatedData)
	}

	const splitParagrafs = (string) => {
		let div = document.createElement('div');
		div.innerHTML = string
		div = div.querySelectorAll('p')

		return (
			<div className="right">
				{div[0] ? (
					<a href={`mailto:${div[0].innerHTML}`}>{div[0].innerHTML}</a>
				) : null}
				{div[1] ? (
					<a href={`tel:${div[1].innerHTML}`}>{div[1].innerHTML}</a>
				) : null}
			</div>
		)
	}

	const footerInsert = () => {
		let div = document.createElement('div');
		div.innerHTML = data.content
		div = div.querySelectorAll('div')

		return (
			<>
				<div className="contact-item">
					<div className="title" dangerouslySetInnerHTML={{ __html: div[0].innerHTML}}/>
					<div className="contact-item-content">
						<div className="full iosevka">
							<p>E-wydania numeru bieżącego i numerów archiwalnych, oraz prenumeratę cyfrowego wydania RM można zamówić: <br /><br />
								<a style={{backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/kontakt/pwm-edition.png)`}} className="contact-icon" href="https://pwm.com.pl/pl/sklep/serie/181/ruch-muzyczny" target="_blank" rel="noopener noreferrer">PWM/e-wydania</a>
								<a style={{backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/kontakt/virtualo.png)`}} className="contact-icon" href="https://virtualo.pl/?q=ruch%20muzyczny" target="_blank" rel="noopener noreferrer">Virtualo</a>
								<a style={{backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/kontakt/publio.png)`}} className="contact-icon" href="https://www.publio.pl/ruch-muzyczny,eq496752.html?keyword=ruch%20muzyczny" target="_blank" rel="noopener noreferrer">Publio</a>
								<a style={{backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/kontakt/e-kiosk.png)`}} className="contact-icon" href="http://www.e-kiosk.pl/index.php?page=titleissues&id_title=165259" target="_blank" rel="noopener noreferrer">e-Kiosk</a>
								<a style={{backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/kontakt/e-kiosk.png)`}} className="contact-icon" href="https://www.egazety.pl/polskie-wydawnictwo-muzyczne/e-wydanie-ruch-muzyczny.html" target="_blank" rel="noopener noreferrer">E-gazety</a>
								<a style={{backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/kontakt/nexto.png)`}} className="contact-icon" href="https://www.nexto.pl/e-prasa/ruch_muzyczny_p1172708.xml" target="_blank" rel="noopener noreferrer">Nexto</a>
							</p>
							{<div dangerouslySetInnerHTML={{ __html: div[1].innerHTML}}/>}
						</div>
					</div>
				</div>
				<div className="contact-box">
					<div className="content">
						<div>
							<div className="header">
								<div className="title" dangerouslySetInnerHTML={{ __html: div[2].innerHTML}}/>
							</div>
							<div className="separtor separatorSpace">
								<div className="title" dangerouslySetInnerHTML={{ __html: div[3].innerHTML}}/>
								<div className="label1" dangerouslySetInnerHTML={{ __html: div[4].innerHTML}}/>
								<div className="label1" dangerouslySetInnerHTML={{ __html: div[5].innerHTML}}/>
								<div className="label1" dangerouslySetInnerHTML={{ __html: div[6].innerHTML}}/>
								<div className="label2" dangerouslySetInnerHTML={{ __html: div[7].innerHTML}}/>
							</div>

						</div>
						<div>
							<div className="header">
								<div className="title" dangerouslySetInnerHTML={{ __html: div[8].innerHTML}}/>
							</div>
							<div className="separatorSpace">
								<div className="title" dangerouslySetInnerHTML={{ __html: div[9].innerHTML}}/>
								<div className="label1" dangerouslySetInnerHTML={{ __html: div[10].innerHTML}}/>
								<div className="label1" dangerouslySetInnerHTML={{ __html: div[11].innerHTML}}/>
								<div className="label2" dangerouslySetInnerHTML={{ __html: div[12].innerHTML}}/>
							</div>

						</div>
					</div>
				</div>
			</>
				/*template*/
				/*<div>PRENUMERATA</div>
				<div>
					<p>Wersje papierowe bieżącego i archiwalnych numerów Ruchu Muzycznego można kupić w <a href="https://pwm.com.pl/pl/sklep/serie/181/ruch-muzyczny" target="_blank" rel="noopener noreferrer">internetowej księgarni PWM</a></p>
					<p>Kontakt telefoniczny w sprawie prenumeraty: <a href="tel:+124227044" rel="noopener noreferrer">12 422 70 44</a> wew. 183 lub <a href="tel:885860336" rel="noopener noreferrer">885 860 336</a>, mail:<a href="mailto:prenumerata@pwm.com.pl"> prenumerata@pwm.com.pl</a></p>
				</div>
				<div>WYDAWCA</div>
				<div>Polskie Wydawnictwo Muzyczne</div>
				<div>al. Krasińskiego 11a, 31-111 Kraków</div>
				<div>Oddział w Warszawie</div>
				<div>ul. Fredry 8, 00-097 Warszawa</div>
				<div><a href="mailto:pwm@pwm.com.pl">pwm@pwm.com.pl</a></div>
				<div>BIURO REKLAMY</div>
				<div>Maciej Kucharski</div>
				<div>ul. Fredry 8, 00-097 Warszawa</div>
				<div>tel. +48 885 860 332</div>
				<div><a href="mailto:maciej_kucharski@pwm.com.pl">maciej_kucharski@pwm.com.pl</a></div>*/
		)
	}

	const normalTextPage = () => {
		return (
			<main>
				<section id="text-view">
					<div className="article-header-wrapper">
						<div className="article-heading">
							<h2 dangerouslySetInnerHTML={{ __html: data.title }} />
							<h3 dangerouslySetInnerHTML={{ __html: data.excerpt }} />
						</div>
					</div>
					<div className="article-content-wrapper">
						<div className="article-text" dangerouslySetInnerHTML={{ __html: data.content }} />
					</div>
				</section>
			</main>
		)
	}

	const aboutUsTextPage = () => {
		return (
			<main className="text-page" id="contact-page">
				<div className="title-wrapper">
					<h2 dangerouslySetInnerHTML={{ __html: data.title }} />
				</div>
				<div className="content-wrapper">
					<div className="content">
						<div className="img-box">
							<img src={about_img} alt="4 newspapers" />
							<div className="description">Tak zmieniała się forma wydania drukowanego: 1961, 2011 2014, 2020</div>
						</div>

						<div className="article-content-wrapper">
							<div className="article-text" dangerouslySetInnerHTML={{ __html: data.content }} />
						</div>

						<div className="contact-box desktop-inline about">
							<div className="header">
								<div className="title"></div>
								<div className="title"></div>
							</div>
							<div className="content">
								<div className="label4" dangerouslySetInnerHTML={{__html: dataAboutLeftColumn.content}}/>
								<div>
									<div className="label3" dangerouslySetInnerHTML={{__html: dataAboutRightColumn.content}}/>

								</div>
							</div>
						</div>
						<div className="mobile" dangerouslySetInnerHTML={{__html: dataAboutLeftColumn.content}}/>
						<div className="mobile" dangerouslySetInnerHTML={{__html: dataAboutRightColumn.content}}/>
					</div>
				</div>
			</main>
		)
	}

	const contactTextPage = () => {
		return (
			<main className="text-page" id="contact-page">
				<div className="title-wrapper">
					<h2>{data.title}</h2>
				</div>
				<div className="content-wrapper">
					{personsData2.map((item, index) => {
						return (
							<div className="contact-item" key={index}>
								<div className="title-image">
									<div className="title" dangerouslySetInnerHTML={{__html: item[0].meta["og:title"]}}/>
									<div className="image" style={{backgroundImage: `url(https://ruchmuzyczny.pl/cms/${item[0].meta["og:image"]})`}}/>
								</div>

								<div className="contact-item-content">
									<div className="left">
										<span className="bigLeft" dangerouslySetInnerHTML={{__html: item[1].title}}/>
										<span className="smallLeft" dangerouslySetInnerHTML={{__html: item[0].meta["og:description"]}}/>
									</div>
									{splitParagrafs(item[1].content)}
								</div>
							</div>
						)
					})}
					<div id={"footer"}/>
					{footerInsert()}
				</div>
			</main>
		)
	}

	const selectTextPage = () => {
		switch (props.dataId) {
			case 2:
				return contactTextPage();
			case 5:
				return aboutUsTextPage();
			default:
				return normalTextPage();
		}
	}


	return (
		props.dataId !== 2 ? (
			data ? (
				selectTextPage()
			) : <Loader name={name} />
		) : (
			isFetching ? (
				selectTextPage()
			) : <Loader name={name} />

		)
	);
}

export default TextPage;
