import React, { useState, useEffect } from 'react';
import NewsItem from './NewsItem';
import NewsBanner from './NewsBanner';
import { mobile } from '../Helpers';
import './MainNews.scss';



function MainNews(props) {

    const name = "MainNews"
    // Current background order
    const [activeBanner, changeBanner] = useState(0);
    const [data, updateData] = useState();

    let newsdata = `${props.data}/slider`;

    // Check url
    // console.log("MainNews url: " + newsdata)
    
    let imageUrl = `${props.data}/storage/dharsa-slider/`;
    let banner = data ? <NewsBanner url={data[activeBanner].url} background={imageUrl + data[activeBanner].image} /> : <NewsBanner />
    

    // Get Data
    useEffect(() => {
        fetch(newsdata)
            .then(res => res.json())
            .then((out) => {
                updateData(out.sort((a, b) => (a.ord > b.ord) ? 1 : -1));
                console.log(name + " successfuly loaded")
            })
            .catch(err => { throw err });
        if (!mobile()) {
            window.addEventListener("scroll", function () {
                if (document.getElementById("news-hover")) {
                    document.getElementById("news-hover").classList.add("active")
                }
            })
        }
    }, []);

    // Autorotation
    useEffect(() => {
        const interval = setInterval(() => {
            activeBanner < data.length - 2 ? changeBanner(activeBanner + 1) : changeBanner(0)
        }, 4000);

        return () => clearInterval(interval);
    });

    // Update banner onClick
    const updateBanner = (order, url) => {
        changeBanner(order);
    };

    const enterItem = e => {
        e.currentTarget.classList.add("active")
    }

    const leaveItem = e => {
        e.currentTarget.classList.remove("active")
    }

    let newsList = data ? (data.map((item, index) => {
        let tag = item.category ? item.category : null;
        let title = item.title ? item.title : null;
        let author = item.author ? item.author : null;
        let url = item.url;
        return (
            index === 3 ? (
                <div id="news-hover" onMouseEnter={enterItem} onMouseLeave={leaveItem} key={index} className={`news-item news-item-hover`}>
                    <a href={item.url}>
                    <div className="image" style={{ backgroundImage: `url(${imageUrl + item.image})` }}></div>
                    <div className="active-border"></div>
                    <div className="tag">{item.tag}</div>
                    <div className="title">{item.title}</div>
                    <div className="author">{item.author}</div>
                    </a>
                </div>
            ) : (
                    <NewsItem active = { activeBanner } key = { index } order = { index } action = { updateBanner } tag = { tag } url = { url } title = { title } author = { author } />
            )
            
        )
    })) : null;

    let newsListMobile = data ? (data.map((item, index) => {
        let tag = item.category ? item.category : null;
        let title = item.title ? item.title : null;
        let author = item.author ? item.author : null;
        let img = item.image ? item.image : "https://dev.seraaq.com/pwm/temp/img/default.png";
        let url = item.url;
        return (
                <NewsItem active={activeBanner} key={index} order={index} tag={tag} title={title} author={author} img={imageUrl + img} url={url}/>
        )
    })) : null;

    return (
        <section id="main-news">
            {mobile()
                ? null
                : data ? (
                    <div className="news-bannerMainWrapper">
                        {data.map((item, index) => {
                            return (
                                item.ord !== "4" ? (
                                        <a key={index} className={`${activeBanner === index ? "news-bannerMainActive" : ""}`} target="_blank" rel="noopener noreferrer" href={item.url}>
                                            <div className={`${"news-bannerMain"} ${activeBanner === index ? "news-bannerMainActive" : ""}`} style={{backgroundImage: `url(${imageUrl + item.image})`}}/>
                                        </a>
                                    ) : null
                            )
                        })}
                    </div>
                    ) : <NewsBanner />
            }
            
            {mobile() ? (
                <div className="news-container">
                    {newsListMobile}
                </div>
            ) : (
                <div className="news-container">
                    {newsList}
                </div>
            )}
        </section>
    );
}

export default MainNews;

