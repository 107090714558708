import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import './ArticleActions.scss';
import {mobile} from "../Helpers"
import fb from '../../assets/icons/icon-facebook.svg'
import tw from '../../assets/icons/twitter.svg'
import send from '../../assets/icons/icon-send.svg'
import print from '../../assets/icons/printer.svg'

function ArticleActions(props) {

    const [scrolled, updateScrolled] = useState(false);

    let { id } = useParams();

    id = parseInt(id);

    const articleLink = `www.ruchmuzyczny.pl/article/${id}`

    useEffect(() => {
        if(mobile()){
            window.addEventListener("scroll", scrolledHandler)
        }

        return () => window.removeEventListener("scroll", scrolledHandler)
    }, [props])

    const scrolledHandler = () => {
        if(window.scrollY > 700){
            updateScrolled(true)
        } else if(!scrolled && window.scrollY) {
            updateScrolled(false)
        }
    }

	return (
        <div>
            <div className="article-actions">
                <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}>
                    <div className="box-wrapper">
                        <div className="box">
                            <img src={fb} alt=""/>
                        </div>
                        <div>Facebook</div>
                    </div>
                </a>
                <a href={`https://twitter.com/intent/tweet?text=${props.data.title}&url=${window.location.href}`}>
                    <div className="box-wrapper">
                        <div className="box">
                            <img src={tw} alt=""/>
                        </div>
                        <div>Twitter</div>
                    </div>
                </a>
                <a href={`mailto:?subject=Ruch Muzyczny - ${props.data.title}&body=${articleLink}%0D%0D${props.data.excerpt}%0D`}>
                    <div className="box-wrapper">
                        <div className="box">
                            <img src={send} alt=""/>
                        </div>
                        <div>Wyślij</div>
                    </div>
                </a>
                <div className="box-wrapper" onClick={() => window.print()}>
                    <div className="box ">
                        <img src={print} alt=""/>
                    </div>
                    <div>Drukuj</div>
                </div>
            </div>
            {mobile() ? (
                <div id="mobile-actions" className={scrolled ? "scrolled" : ""}>
                    <ul>
                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${articleLink}`}>
                            <li style={{backgroundImage: `url(${fb})`, backgroundSize: "12px"}}></li>
                        </a>

                        <a href={`https://twitter.com/intent/tweet?text=${props.data.title}&url=${articleLink}`}>
                            <li style={{backgroundImage: `url(${tw})`}}></li>
                        </a>

                        <a href={`mailto:?subject=Ruch Muzyczny - ${props.data.title}&body=${articleLink}%0D%0D${props.data.excerpt}%0D`}>
                            <li style={{backgroundImage: `url(${send})`}}></li>
                        </a>
                    </ul>
                </div>
            ) : null}
        </div>
	);
}

export default ArticleActions;