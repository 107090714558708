import React, { useState} from 'react';
import { useHistory, BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './MainMenu.scss';
import { mobile } from '../Helpers'
import img1 from '../../assets/img/preneumerate_img1.png'
import img2 from '../../assets/img/preneumerate_img2.png'
import img3 from '../../assets/img/preneumerate_img3.png'

export default function MainMenu() {
	const [isOpen, openToggle] = useState(false);
	const [searchOpen, searchToggle] = useState(false);
	const [scrollPos, scrollUpdate] = useState(0);
	const [mobileOverflow, updateMobileOverflow] = useState(false)

	let history = useHistory()

	// Create reference to input element
	const inputRef = React.createRef();

	const menuToggle = () => {
		openToggle(!isOpen)
		updateMobileOverflow(!mobileOverflow)
		if ( window.innerWidth < 1010) {
			mobileOverflow ? document.body.style.overflow = "scroll" : document.body.style.overflow = "hidden"
		} else {
			document.body.style.overflow = "scroll"
		}
	}
	
	const searchToggleHandler = () => {
		searchToggle(!searchOpen);
		searchOpen ? (
			inputRef.current.setAttribute("placeholder", "")
		) : (
			inputRef.current.setAttribute("placeholder", "Szukaj...")
		)
		inputRef.current.value = ""
	}

	const opened = isOpen ? "opened" : "";

	const searchOpened = searchOpen ? "opened" : "";

	window.addEventListener("scroll", function () {
		var st = window.pageYOffset || document.documentElement.scrollTop;
		if (st > scrollPos && window.innerWidth > 1010) {
			openToggle(false)
		}
		st <= 0 ? scrollUpdate(0) : scrollUpdate(st);
	}, false);

	const search = (e) => {
		e.preventDefault();
		console.log(history.location.pathname.slice(13))
		if(history.location.pathname.slice(13) !== inputRef.current.value) {
			window.globalSearch = inputRef.current.value;
			history.push({ pathname: `/search-list/${inputRef.current.value}`})
		}
		document.body.style.overflow = "scroll"
		openToggle(!isOpen)
	}


	return (
		<div id="main-menu" className={opened}>
			<div>
				<div className={`menu-item-wrapper ${searchOpen ? "search-open" : ""}`}>
					<a onClick={() => openToggle(false)} href="/article-list/publications">
						<div className="menu-item">
							<span>Wydawnictwa</span>
						</div>
					</a>

					<a onClick={() => openToggle(false)} href="/article-list/relations">
						<div className="menu-item">
							<span>Relacje</span>
						</div>
					</a>

					<a onClick={() => openToggle(false)} href="/article-list/freedom_area">
						<div className="menu-item">
							<span>Swoboda Ruchu</span>
						</div>
					</a>

					<a onClick={() => openToggle(false)} href="/article-list/talks">
						<div className="menu-item">
							<span>Rozmowy</span>
						</div>
					</a>

					<a onClick={() => openToggle(false)} href="/article-list/movement_area">
						<div className="menu-item">
							<span>Strefa Ruchu</span>
						</div>
					</a>

					<a onClick={() => openToggle(false)} href="/video-list">
						<div className="menu-item">
							<span>Wideo</span>
						</div>
					</a> 

					{/* <a onClick={() => openToggle(false)} href="/blog-list">
						<div className="menu-item">
							<span>Blog</span>
						</div>
					</a>  */}

					{/* <a onClick={() => openToggle(false)} href="/anniversary-list">
						<div className="menu-item">
							<div>
								<span>75 lat Ruchu Muzycznego</span>
							</div>
						</div>
					</a>  */}

					<div className={"preWrapper desktop"}>
						<div className="imgWrapper">
							<img className="img1" src={img1} alt={"zdjęcie_1"}/>
							<img className="img2" src={img2} alt={"zdjęcie_2"}/>
							<img className="img3" src={img3} alt={"zdjęcie_3"}/>
						</div>
						<div className="textWrapper">
							<span>Czytaj nas w prenumeracie</span>
							<a onClick={() => openToggle(false)} href="/preneumerate">
								Zamów teraz
							</a>
						</div>
					</div>

					<a className="mobileBlock" onClick={() => openToggle(false)} href="/preneumerate">
						<div className="menu-item">
							<span>Prenumerata</span>
						</div>
					</a>

					<div className={`search-area-mobile mobile`}>
						<form onSubmit={search}>
							<input ref={inputRef} name="name" type="text" />
						</form>
						<button className="search-button" onClick={search} />
					</div>
				</div>
			</div>
			{mobile() ? (
				<div />
			) : (
				<div className={`search-area ${searchOpened}`}>
					<form onSubmit={search}>
						<input ref={inputRef} name="name" type="text" />
					</form>
					<button className="search-button" onClick={searchToggleHandler} />
				</div>
			)}
			<div>
				<button className="menu-button" onClick={menuToggle}></button>
			</div>
		</div>
	)
}