import React from 'react';
import {Link} from "react-router-dom";
import './AuthorBox.scss';

function AuthorBox(props) {

	
	return (
		<div className="author-box">
            {props.data.author ? <div className="author-name">{props.data.author}</div> : null}
            {props.data.author_name ? <div className="author-name">{props.data.author_name}</div> : null}
            {props.data.author_origin ? <div className="author-origin">{props.data.author_origin}</div> : null}
			{props.data.category_name ? (
				<Link to={`/blog-list/${props.data.category_id}`}>
					<div className="author-origin">{props.data.category_name}</div>
				</Link>
			) : null}
        </div>
	);
  }
  
  export default AuthorBox;
