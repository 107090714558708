export function mobile() {
    if (window.innerWidth < 1010) {
        return true
    } else return false
}

export function mobileSmall() {
    if (window.innerWidth < 700) {
        return true
    } else return false
}

// Parse seconds to mm:ss format
export const secondsToMinutes = seconds => Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);

export function minHeight() {
    const headerH = document.getElementsByTagName("header")[0].offsetHeight;
    const footerH = document.getElementsByTagName("footer")[0].offsetHeight;
    const takenH = mobile() ? footerH : headerH + footerH;
    document.getElementsByTagName("main")[0].style.minHeight = `calc(100vh - ${takenH}px)`;
}