import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import ArticleTitle from '../../Components/ArticleTitle';
import FollowButton from '../../Components/FollowButton';
import AuthorBox from '../../Components/AuthorBox';
import ArticleContent from '../../Components/ArticleContent';
import Newsletter from '../../Components/Newsletter'
import SimilarArticles from '../../Components/SimilarArticles'
import './Blog.scss';
import ArticleActions from '../../Components/ArticleActions';
import { mobile } from '../../Components/Helpers';
import Loader from '../../Components/Loader'


function Blog(props) {

	window.scrollTo(0, 0);

	let { id } = useParams();

	id = parseInt(id);

	let { url } = useRouteMatch();

	const name = "Blog"

	// Articles data storage
	const [data, updateData] = useState(false);

	const mainUrl = props.data

	// Static url to get data from
	const dataUrl = `${props.data}/bloger/get_by_id/${id}`;

	// console.log(dataUrl);

	// Get Data
	useEffect(() => {
		fetch(dataUrl)
			.then(res => res.json())
			.then((out) => {
				updateData(out[0]);
				window.category = out[0].category_site;
				/*document.querySelectorAll("#main-menu .menu-item span").forEach(function(e){
					// console.log(e);
					if (e.innerHTML.toLowerCase() === out[0].category_site.toLowerCase()){
						e.classList.add("active")
						e.parentElement.parentElement.classList.add("first-element")
					} else {
						e.classList.remove("active")
						e.parentElement.parentElement.classList.remove("first-element")
					}
				})*/
			})
			.catch(err => { throw err });
			return () => {
				document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
					e.classList.remove("active")
					e.parentElement.parentElement.classList.remove("first-element")
				})
			}
	}, [url]);

	useEffect(() => {
		console.log(name, data);
	}, [data])


	const authorImage = data.image && data.image.length ? <div className="author-image" style={{ backgroundImage: `url(${data.image})` }}></div> : null;
	const articleMetric = data && data.metric && data.metric.length > 1 ? <div className="article-metric" dangerouslySetInnerHTML={{ __html: data.metric }} /> : null

	return (
		data ? (
			<main>
				{mobile() ? (
					<section id="article-view">
						<div className="article-header-wrapper">
							<div>
								<ArticleTitle data={data} />
								<div className="article-box-1">
									<AuthorBox data={data} />
									
								</div>
								<div className="article-box-2">
									{/* <FollowButton /> */}
									<ArticleActions data={data} url={mainUrl} />
								</div>
							</div>
						</div>
						<div className="article-content-wrapper">
							<div>
								<ArticleContent data={data} url={mainUrl} />
							</div>
						</div>
					</section>
				) : (
						<section id="article-view">
							<div className="article-header-wrapper">
								<div>
									{authorImage}
								</div>
								<div>
									<ArticleTitle data={data} />
									<AuthorBox data={data} />
								</div>
							</div>
							<div className="article-content-wrapper">
								<div className={`article-options ${data && data.metric && data.metric.length > 1 ? "" : "no-metric"}`}>
									<ArticleActions data={data} url={mainUrl} />
									{articleMetric}
								</div>
								<div>
									<ArticleContent data={data} url={mainUrl} />
								</div>
							</div>
						</section>
					)

				}

			</main>
		) : <Loader name={name} />

	);

}

export default Blog;
