import React from 'react';
import './VideoControls.scss';

function VideoControls(props) {

    const togglePlay = (e) => {
        props.data.play();
        e.currentTarget.classList.add("active");
    }

    const toggleSound = (e) => {
        props.data.setMute();
        e.currentTarget.classList.toggle("muted");
    }

    const toggleFullscreen = () => {
        props.data.setFullscreen();
    }

    // Calculate current progress
    let currentProgress = (props.data.time / props.data.duration).toFixed(3) * 100;

    // Change current progress (number) to minutes and seconds format
    const secondsToMinutes = seconds => Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2);

    const playClass = props.data.isPlaying ? "play playing" : "play";

    const test = (e) => {
        // console.log("asdasd3211323: ", e.currentTarget.value/100);
        props.data.seekTo(e.currentTarget.value / 100)
    }

    // console.log("isPlaying: ", props.data.isPlaying);

    return (

        <div className="video-controls">
            <div className="time-line">
                <div className="progress-bar">
                    <input type="range" min="0" max="100" onClick={test}/>
                    <div className="current-progress" style={{ width: `${currentProgress}%` }}></div>
                </div>
            </div>
            <div className="controls">
                <div>
                    <button className={playClass} onClick={togglePlay}></button>
                    <div className="time">{secondsToMinutes(props.data.time)}</div>
                    <button className="mute" onClick={toggleSound}></button>
                </div>
                <div></div>
                <div>
                    <button className="fullscreen" onClick={toggleFullscreen}></button>
                </div>
            </div>

        </div>
    );
}

export default VideoControls;

