import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './MainSlider.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loader from '../Loader';
import {mobile} from '../Helpers';
import slug from "slug";

function MainSlider(props) {
    const name = "MainSlider"

    // Articles data storage
    const [data, updateData] = useState(false);

    // Static url to get data from
    const dataUrl = `${window.globalUrl}/articles-list/category/${props.type}${props.limit ? `?limit=${props.limit}` : ""}`

    // Check url
    console.log("MainSlider url: " + dataUrl)

    // Get and update data
    useEffect(() => {
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
            })
            .catch(err => { throw err });
    }, []);

    // Create reference to video element
    const carouselRef = React.createRef();

    // Next & Prev carousel buttons
    const prevSlide = () => {
        carouselRef.current.prev()
    }
    const nextSlide = () => {
        carouselRef.current.next()
    }

    // Amount of slides depending on screen width
    const responsive = {
        0: {
            items: 1,
        },
        400: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
        1200: {
            items: 5,
        }
    }


    return (
        data ? (
            data.length > 0 ? (
                <section id="main-slider">
                    <Link to={`/article-list/${props.type}`}>
                        <h3 className="section-title">{props.title}</h3>
                    </Link>
                    {mobile() ? (
                        <div className="wrapper">
                            {data.slice(0,10).map(function (item, index) {
                                return (
                                    <div key={index}>
                                        <div>{index + 1}</div>
                                        <div>
                                            <Link to={`article/${item.id}${item.title && item.title.length ? '-'+slug(item.title) : ''}`} >
                                                <div className="title">{item.title}</div>
                                                <div className="author">{item.author}</div>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    ) : (
                        <div className="carousel-wrapper">
                            <OwlCarousel ref={carouselRef} className="owl-theme" loop margin={10} dots={false} responsive={props.options ? props.options : responsive}>
                                {data.map(function (item, index) {

                                    // Check if image_small exists than check for standard one else set placeholder
                                    let image;
                                    const itemData = JSON.parse(item.data);
                                    if (itemData.image_small.src.length > 0) {
                                        image = itemData.image_small.src
                                    } else if (itemData.image.src.length > 0) {
                                        image = itemData.image.src
                                    } else {
                                        image = "storage/placeholder.png";
                                    }

                                    return (
                                        <Link key={index} to={`article/${item.id}${item.title && item.title.length ? '-'+slug(item.title) : ''}`} >
                                            <div >
                                                <div className="image" style={{ backgroundImage: `url(${props.mainUrl}/${image})` }}></div>
                                                <div className="tag"></div>
                                                <div className="title">{item.title}</div>
                                                <div className="author">{item.author}</div>
                                                {item.video ? <div className="video">{item.video}</div> : null}
                                                {item.audio ? <div className="audio">{item.audio}</div> : null}
                                                {item.gallery ? <div className="gallery">{item.gallery}</div> : null}
                                            </div>
                                        </Link>
                                    )
                                })}
                            </OwlCarousel>
                            <button className="carousel-button carousel-prev" onClick={prevSlide}></button>
                            <button className="carousel-button carousel-next" onClick={nextSlide}></button>
                        </div>
                    )}
                </section>
            ) : null

        ) : <Loader name={name} />
    );
}
export default MainSlider;