import React from 'react';

import "../../assets/scss/index.scss";
import "../../assets/fonts/Schnyder/fonts.css";
import "../../assets/fonts/GuyotPress/GuyotPress-Italic1.css";
import "../../assets/fonts/GuyotPress/GuyotPress-Italic2.css";
import "../../assets/fonts/GuyotPress/GuyotPress-Italic3.css";
import "../../assets/fonts/GuyotPress/GuyotPress-Regular1.css";
import "../../assets/fonts/GuyotPress/GuyotPress-Regular2.css";
import "../../assets/fonts/GuyotPress/GuyotPress-Regular3.css";
import "../../assets/fonts/Iosevka/font.css";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Header from '../Header';
import Main from '../Main';
import Article from '../Article';
import AnniversaryArticle from '../75AnniversaryArticle';
import Blog from '../Blog';
import VideoArticle from '../VideoArticle'
import ArticleList from '../ArticleList'
import Anniversary from '../75Anniversary'
import BlogList from '../BlogList'
import VideoList from '../VideoList'
import TagList from '../TagList'
import TextPage from '../TextPage'
import PaperEditionID from '../PaperEdition'
import AuthorPage from '../AuthorPage'
import SearchList from '../SearchList'
import Footer from '../Footer'
import ReactGA from 'react-ga4';
import NewsletterSite from "../Newsletter/NewsletterSite";
import Preneumerate from "../PreneumeratePage";

const App = () =>{

    ReactGA.initialize("G-T9WFJ22BZJ")
    ReactGA.send("pageview")

    const globalUrl = "https://ruchmuzyczny.pl/cms";
    window.globalUrl =  globalUrl;

    console.log("test")

    return (
        <Switch>
            <Route exact path={`/`}>
                <Header />
                <Main data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/article/:id/`}>
                <Header />
                <Article data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/article-list/:id/`}>
                <Header />
                <ArticleList data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/author/`}>
                <Header />
                <AuthorPage />
                <Footer />
            </Route>

            <Route path={`/video-list/`}>
                <Header />
                <VideoList />
                <Footer />
            </Route>

            <Route path={`/tag-list/:id/`}>
                <Header />
                <TagList />
                <Footer />
            </Route>

            <Route path={`/video/:id/`}>
                <Header />
                <VideoArticle data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/search-list/:id`}>
                <Header />
                <SearchList />
                <Footer />
            </Route>

            <Route path={`/paper-edition/:id`}>
                <Header />
                <PaperEditionID />
                <Footer />
            </Route>

            <Route path={`/blog/:id`}>
                <Header />
                <Blog data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/blog-list/:id`}>
                <Header />
                <BlogList data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/blog-list`}>
                <Header />
                <BlogList data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/contact`}>
                <Header />
                <TextPage data={globalUrl} dataId={2} />
                <Footer />
            </Route>

            <Route path={`/regulations`}>
                <Header />
                <TextPage data={globalUrl} dataId={3} />
                <Footer />
            </Route>

            <Route path={`/privacy-policy`}>
                <Header />
                <TextPage data={globalUrl} dataId={4} />
                <Footer />
            </Route>

            <Route path={`/about`}>
                <Header />
                <TextPage data={globalUrl} dataId={5} />
                <Footer />
            </Route>

            <Route path={`/declaration`}>
                <Header />
                <TextPage data={globalUrl} dataId={22} />
                <Footer />
            </Route>

            <Route path={`/preneumerate`}>
                <Header />
                <Preneumerate data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/newsletter`}>
                <Header />
                <NewsletterSite />
                <Footer />
            </Route>

            <Route path={`/anniversary-list`}>
                <Header />
                <Anniversary data={globalUrl} />
                <Footer />
            </Route>

            <Route path={`/anniversary-article/:id/`}>
                <Header />
                <AnniversaryArticle data={globalUrl} />
                <Footer />
            </Route>

            <Route exact path={`*`}>
                <Header />
                <Redirect to="/" />
                <Footer />
            </Route>
        </Switch>
    );
};


function Builder() {
    return (
        <Router>
            <App/>
        </Router>
    );
}

export default Builder;