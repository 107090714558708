import React, { useEffect, useState } from 'react';
import { secondsToMinutes } from '../Helpers';
import ReactPlayer from 'react-player';
import './VideoPlayer.scss';

function VideoPlayer(props) {

	const [duration, setDuration] = useState(0);
	const [currentTime, timeUpdate] = useState(0);
	const [isPlaying, changePlaying] = useState(false);
	const [isLightMode, changeLightMode] = useState(false);
	const [isMuted, toggleMuted] = useState(false);

	// Create reference to video element
	const videoRef = React.createRef();

	const getFullDuration = () => {
		setDuration(videoRef.current.getDuration())
	}

	// Play / Pause video based on state
	const togglePlay = (e) => {
		changePlaying(!isPlaying);
		changeLightMode(false);
		// e.currentTarget.classList.toggle("playing");
	}

	// Mute / Unmute based on state
	const toggleMute = (e) => {
		toggleMuted(!isMuted)
		// e.currentTarget.classList.toggle("muted");
	}

	// % of played music
	let durationPart = (currentTime / duration * 100).toFixed(3);

	const handleProgress = e => {
		timeUpdate(e.playedSeconds);
	}

	const seekTo = e => {
		console.log(e.currentTarget.value/100);
		videoRef.current.seekTo(e.currentTarget.value/100)
	}

	useEffect(() => {
        changeLightMode(`https://img.youtube.com/vi/${props.youtube_id}/hqdefault.jpg`);
    }, [props]);


	return (
		<div className="video-player">
			<ReactPlayer onReady={getFullDuration} muted={isMuted} volume={0.8} onProgress={handleProgress} onPlay={() => changePlaying(true)} onPause={() => changePlaying(false)} playing={isPlaying} ref={videoRef} url={props.source} light={isLightMode} />
			<div className="controls">
				<button className={`play ${isPlaying ? "playing" : ""}`} onClick={togglePlay}></button>
				<button className={`mute ${isMuted ? "muted" : ""}`} onClick={toggleMute}></button>
				<div className="current-time">{secondsToMinutes(currentTime)}</div>
				<div className="progress">
					<div className="progress-wrapper" >
						<input type="range" min="0" max="100" onClick={seekTo} />
						<div className="progress-section">
							<div className="progress-bar" style={{ width: `${durationPart}%` }}></div>
						</div>
					</div>
				</div>
				<div className="full-time">{secondsToMinutes(duration)}</div>
			</div>
		</div>
	);
}

export default VideoPlayer;


