import React, { useState, useEffect } from 'react';
import './ArticleTitle.scss'
import {AddArticle} from '../Ads'
import useWindowDimensions from '../../Components/Helpers/useWindowDimensions';
import { hyphenate } from "hyphen/pl";

function ArticleTitle(props) {

	const { height, width } = useWindowDimensions();
	/*const [hyphenateTitle ,setHyphenateTitle ] = useState(props.data.title.replace(/\uFFFD/g, ''))*/

	let title =''

	props.anniversary === true 
		? title = '<div class="row_year_img"><a href="/" class="year">75</a><span class="year_description">lat</span><span class="line"></span></div>' + props.data.title.replace(/\uFFFD/g, '')
		: title = props.data.title.replace(/\uFFFD/g, '')

	useEffect(() => {
		let quote = document.getElementById("title")

		let numWords = quote.innerHTML.split(' ');

		let longestWord = ""

		if(width < 430) {
			for(let i = 0; i < numWords.length; i++) {
				if(longestWord.length < numWords[i].length) {
					longestWord = numWords[i];
				}
			}

			if(longestWord.length > 15) {
				quote.style.fontSize = "9vw"
				quote.style.lineHeight = "9.5vw"

			} else if(longestWord.length === 15 ) {
				quote.style.fontSize = "9.5vw"
				quote.style.lineHeight = "10vw"
			} else if(longestWord.length <= 14 && longestWord.length >= 12) {
				quote.style.fontSize = "10vw"
				quote.style.lineHeight = "10vw"
			} else {
				quote.style.fontSize = "42px"
				quote.style.lineHeight = "47px"
			}

		} else if(width < 1010) {
			quote.style.fontSize = "42px"
			quote.style.lineHeight = "47px"
			quote.style.wordBreak = "unset"
		} else {
			quote.style.fontSize = "95px"
			quote.style.lineHeight = "90px"
			quote.style.wordBreak = "unset"
		}

		/*if(width < 430) {
			hyphenate(hyphenateTitle).then(result => {
				setHyphenateTitle(result)
			});
		} else {
			title = props.data.title.replace(/\uFFFD/g, '')
		}*/

	}, [width]);
	
	return (
		<div className="article-heading">
			<AddArticle />
			<h2 lang={'pl'} id={"title"} dangerouslySetInnerHTML={{ __html: title}} />
			{props.data.excerpt ? <h3 dangerouslySetInnerHTML={{ __html: props.data.excerpt.replace(/\uFFFD/g, '') }} /> : null}
			{props.data.lead ? <h3 dangerouslySetInnerHTML={{ __html: props.data.lead.replace(/\uFFFD/g, '') }} /> : null}
		</div>
	);
}

export default ArticleTitle;
