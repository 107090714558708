import React from 'react';
import './Podcasts.scss';
import OwlCarousel from 'react-owl-carousel';
import AudioPlayer from '../AudioPlayer/index';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';




function Podcasts(props) {
    
    const prevSlide = () => {
        document.querySelector("#podcasts-carousel .owl-prev").click();
    }

    const nextSlide = () => {
        document.querySelector("#podcasts-carousel .owl-next").click();
    }
    

	return (
        <section id="podcasts-slider">
            <h3 className="section-title">Podcasty</h3>
            <div className="carousel-wrapper">
                <OwlCarousel id="podcasts-carousel" className="owl-theme" loop margin={0} items={1} nav={false} dots={false} mouseDrag={false}>
                    <div className="podcast-slide">
                        <div className="slide-wrapper">
                            <div className="player">
                                <AudioPlayer source={"http://dev.seraaq.com/pwm/temp/music.mp3"} />
                            </div>
                            <div>
                                <div className="image" style={{backgroundImage: "url(http://dev.seraaq.com/pwm/temp/img/article-small-6.png)"}}></div>
                                <div>
                                    <div className="title">Teraz będzie raj! Tak opisuje swoje przeżycia</div>
                                    <div className="subtext">Nasz katamaran czeka zacumowany na Martynice. Jesteśmy w komplecie, osiem kobiet i kapitan, który będzie jedynym mężczyzną na jachcie</div>
                                    <div className="author">Olgierd Pisarenko</div>
                                    <div className="media">23:30</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="podcast-slide">
                        <div className="slide-wrapper">
                            <div className="player">
                                <AudioPlayer source={"http://dev.seraaq.com/pwm/temp/music.mp3"}/>
                            </div>
                            <div>
                                <div className="image" style={{backgroundImage: "url(http://dev.seraaq.com/pwm/temp/img/article-small-6.png)"}}></div>
                                <div>
                                    <div className="title">Teraz będzie raj! Tak opisuje swoje przeżycia</div>
                                    <div className="subtext">Nasz katamaran czeka zacumowany na Martynice. Jesteśmy w komplecie, osiem kobiet i kapitan, który będzie jedynym mężczyzną na jachcie</div>
                                    <div className="author">Olgierd Pisarenko</div>
                                    <div className="media">23:30</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
                <button className="carousel-button carousel-prev" onClick={prevSlide}></button>
                <button className="carousel-button carousel-next" onClick={nextSlide}></button>
            </div>

        </section>

	);
  }
  
export default Podcasts;


