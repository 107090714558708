import React from 'react';
import { Link } from "react-router-dom";
import './Anniversary.scss'
import okladka from '../../../src//assets//img//RM-okladki-75lat.png'

function Anniversary(props) {

    return (
        <div className="container_banner_main">
            <div className="container_banner">
                <div className="banner" >
                    <div className="row_year_img">
                        <span class="year">
                            <Link to={"../anniversary-list"}>
                                <span >75</span>
                            </Link>
                        </span>
                        <span class="year_description">
                            <Link to={"../anniversary-list"}>
                                <span >lat</span>
                            </Link>
                        </span>

                        <div className= "img">
                            <img src={okladka} alt=""/>
                        </div>
                    </div>
                
                    <div className="tekst">
                        <span className="tekst_1">Wspomnienia · rozmowy · teksty · fotografie · okładki</span>
                        <span className="tekst_2">Z archiwum „Ruchu Muzycznego”</span>
                    </div>

                    <div className="button"> 
                        <Link to={"../anniversary-list"}>
                            <span >Zobacz</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Anniversary;