import React from 'react';
import './NewsItem.scss';
import {mobile} from '../../Helpers';


function NewsItem(props) {
  const active = props.active === props.order ? "active" : "";


  return(
	mobile() ? 
	// Mobile version
	(
	  <a href={props.url} className={`news-item`}>
		<div className="image" style={{backgroundImage: `url(${props.img})`}}></div>
		<div>
			<div className="tag">{props.tag}</div>
			<div className="title">{props.title}</div>
			<div className="author">{props.author}</div>
		</div>
	  </a>
	) : ( 
	// Desktop version
	  <div className={`news-item ${active}`} order={props.order} onMouseEnter={props.action.bind(null, props.order, props.url)}>
		<a href={props.url}>
			<div className="active-border"></div>
			<div className="tag">{props.tag}</div>
			<div className="title">{props.title}</div>
			<div className="author">{props.author}</div>
		</a>
	  </div>
	)
  )

}

export default NewsItem;

