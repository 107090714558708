import React, { useState, useEffect } from 'react';
import {useParams, useRouteMatch} from "react-router-dom";
import Newsletter from '../../Components/Newsletter';
import VideoPlayer from '../../Components/VideosHub/VideoPlayer';
import VideoControls from '../../Components/VideosHub/VideoControls';
import VideoText from '../../Components/VideosHub/VideoText';
import Loader from '../../Components/Loader';

function VideoArticle(props) {
    let { id } = useParams();
    id = parseInt(id);
    let { url } = useRouteMatch();

    const name = "VideoArticle"

    // Articles data storage
    const [data, updateData] = useState(false);

    // Static url to get data from
    const dataUrl = `${props.data}/video/single-video/${id}`;

    // Get Data
    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out[0]);
            })
            .catch(err => { throw err });
            document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                if (e.innerHTML.toLowerCase().indexOf("wideo") > -1) {
                    e.classList.add("active");
                    e.parentElement.parentElement.classList.add("first-element")
                } else {
                    e.classList.remove("active")
                    e.parentElement.parentElement.classList.remove("first-element")
                }
            })
            return () => {
                document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                    e.classList.remove("active")
                    e.parentElement.parentElement.classList.remove("first-element")
                })
            }
    }, [url]);

    useEffect(() => {
        changeLightMode(`https://img.youtube.com/vi/${data.youtube_id}/hqdefault.jpg`);
    }, [data]);

    const [duration, setDuration] = useState(0);
    const [currentTime, timeUpdate] = useState(0);
    const [isPlaying, changePlaying] = useState(false);
    const [isLightMode, changeLightMode] = useState(true);
    const [isMuted, toggleMuted] = useState(false);
    const [fullScreen, isFullscreen] = useState(false);
    const [seekTo, seekUpdate] = useState(false);

    // Play/pause video
    const togglePlayHandler = () => {
        changePlaying(!isPlaying);
        changeLightMode(false);
    }

    // Pass current time to VideoPlayer component
    const timeUpdateHandler = (e) => timeUpdate(e);
    const toggleMuteHandler = () => toggleMuted(!isMuted);
    const durationHandler = (e) => setDuration(e);
    const setPlay = () => changePlaying(true)
    const setPause = () => changePlaying(false)
    const setFullscreen = () => isFullscreen(true)
    const seekUpdateHandler = (e) => seekUpdate(e)
    const test = () => isFullscreen(false)
    
    // Set of data sent to VideoControls component
    let controlsData = {
        time: currentTime,
        duration: duration,
        play: togglePlayHandler,
        setPlay: setPlay,
        setPause: setPause,
        setMute: toggleMuteHandler,
        setFullscreen: setFullscreen,
        isPlaying: isPlaying,
        seekTo: seekUpdateHandler
    }

    // Set of data sent to VideoPlayer component
    let playerData = {
        time: timeUpdateHandler,
        duration: durationHandler,
        play: isPlaying,
        lightMode: isLightMode,
        muted: isMuted,
        fullScreen: fullScreen,
        setPlay: setPlay,
        setPause: setPause,
        setfullScreen: test,
        seekTo: seekTo
    }

    return (
        data ? (
            <main>
                <section id="article-view">
                    <VideoPlayer data={data} functions={playerData} />
                    <div className="article-content-wrapper max-1000">
                        <div>
                            {/* <ArticleActions /> */}
                        </div>
                        <div>
                            <div className="article-addons-wrapper">
                                <VideoControls data={controlsData} />
                                <VideoText data={data} />
                                {/* <MainSlider data={data} options={responsive} /> */}
                                <Newsletter />
                                {/* <SimilarArticles /> */}
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        ) : <Loader name={name} />
    );
}

export default VideoArticle;