import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Switch, Route, useParams, useRouteMatch, Link} from "react-router-dom";
import './Preneumerate.scss';
import { minHeight } from '../../Components/Helpers';
import Loader from '../../Components/Loader'
import photo from '../../assets/img/preneumerate_img.png'

function Preneumerate(props) {

	let { url } = useRouteMatch();

	const name = "Preneumerate"

	const [data, updateData] = useState(false);

	const dataUrl = `${props.data}/subscription-get`;

	// Get Data
	useEffect(() => {
		window.scrollTo(0, 0);
		fetch(dataUrl)
			.then(res => res.json())
			.then((out) => {
				updateData(out);
				minHeight();
			})
			.catch(err => { throw err });
	}, [url]);


	return (
		data ? (
			<main className="prenumeWrapper">
				<h1 className="title" dangerouslySetInnerHTML={{ __html: data.main_title}}/>
				<div className="collWrapper">
					<div className="coll">
						<span className="collTitle" dangerouslySetInnerHTML={{__html :data.left_title}}/>
						<div className="collRow">
							<div className="rowColl">
								<span className="rowCollTitle">{data.left_subscription_name_1}</span>
								<span className="price">{data.left_subscription_value_1}</span>
							</div>
							<div className="rowColl">
								<span className="rowCollTitle">{data.left_subscription_name_2}</span>
								<span className="price">{data.left_subscription_value_2}</span>
							</div>
						</div>
						<div className="buttonAbove" dangerouslySetInnerHTML={{__html: data.left_button_above}}/>
						<a className="button" target={"_blank"} href={data.left_button_url} >{data.left_button_value}</a>
						<div className="buttonUnder" dangerouslySetInnerHTML={{__html: data.left_button_under}}/>
					</div>
					<div className='separator2'/>
					<div className="coll">
						<span className="collTitle" dangerouslySetInnerHTML={{__html :data.right_title}}/>
						<div className="collRow">
							<div className="rowColl">
								<span className="rowCollTitle">{data.right_subscription_name_1}</span>
								<span className="price">{data.right_subscription_value_1}</span>
							</div>
							<div className="rowColl">
								<span className="rowCollTitle">{data.right_subscription_name_2}</span>
								<span className="price">{data.right_subscription_value_2}</span>
							</div>
						</div>
						<div className="buttonAbove" dangerouslySetInnerHTML={{__html: data.right_button_above}}/>
						<a className="button" target={"_blank"} href={data.right_button_url} >{data.right_button_value}</a>
						<div className="buttonUnder" dangerouslySetInnerHTML={{__html: data.right_button_under}}/>
					</div>
				</div>
				<div className="bottomSection">
					<div className="bottomWrapper">
						<div className="bottomTitle" dangerouslySetInnerHTML={{__html: data.section_title}}/>
						<div className="bottomText" dangerouslySetInnerHTML={{__html: data.section_text}}/>
						<img className="preMobile" src={photo} alt={'zdjęcie'}/>
						<div className="buttonWrapper">
							<a className="button bottom" target={"_blank"} href={data.section_button_url}>{data.section_button_value}</a>
						</div>
					</div>
					<img className="preDesktop" src={photo} alt={'zdjęcie'}/>
				</div>
				<div className="contact" dangerouslySetInnerHTML={{__html: data.section_text_under}}/>
			</main>
		) : <Loader name={name} />
	);
}

export default Preneumerate;
