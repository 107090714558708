import React, { useEffect } from 'react';
import { findDOMNode } from 'react-dom'
import './VideoPlayer.scss';
import screenfull from 'screenfull'
import ReactPlayer from 'react-player';


function VideoPlayer(props) {


    // Create reference to video element
    const videoRef = React.createRef();

    const getFullDuration = () => {
        //console.log(videoRef.current.getDuration())
        props.functions.duration(videoRef.current.getDuration())
    }

    const handleProgress = e => {
        props.functions.time(e.playedSeconds);
    }


    // Fullscreen "listener"
    useEffect(() => {
        if(props.functions.fullScreen){
            screenfull.request(findDOMNode(videoRef.current));
            props.functions.setfullScreen();
        }
    }, [props.functions.fullScreen]);


    // SeekTo "listener"
    useEffect(() => {
        if (props.functions.seekTo) {
            videoRef.current.seekTo(props.functions.seekTo)
        }
    }, [props.functions.seekTo]);

    return (
        <div className="video-player">
            <ReactPlayer onReady={getFullDuration} muted={props.functions.muted} volume={0.8} onProgress={handleProgress} onPlay={() => props.functions.setPlay()} onPause={() => props.functions.setPause()} playing={props.functions.play} ref={videoRef} url={props.data.embed} light={props.functions.lightMode} />
            {/* <button onClick={test}>test</button> */}
        </div>
    );
}

export default VideoPlayer;

