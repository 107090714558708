import React from "react";

// Szeroka reklama w widoku listy kategorii artykułów i pod banerem na stronie głównej
const AddWide1 = () => (
	<div className="article-adwrapper center" style={{maxWidth: "970px", margin: "auto", width:  "100%"}}>
		{/* <iframe
            id="abeb952a"
			name="abeb952a"
			src="https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=5&amp;cb=INSERT_RANDOM_NUMBER_HERE"
			frameBorder="0"
			scrolling="no"
			width="970"
			height="70"
            allow="autoplay"
            title="AddWide1">
			<a href="https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=a64f32d9&amp;cb=INSERT_RANDOM_NUMBER_HERE" target="_blank">
				<img src="https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=5&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a64f32d9" border="0" alt="" />
			</a>
		</iframe> */}
		<iframe 
			id="a652f5d3" 
			name="a652f5d3" 
			src="https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=13&amp;cb=INSERT_RANDOM_NUMBER_HERE" 
			frameBorder="0"
			scrolling="no"
			width="970"
			allow="autoplay" 
			title="AddWide1">
			<a href="https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=af4bb08f&amp;cb=INSERT_RANDOM_NUMBER_HERE" target="_blank">
				<img src="https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=13&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=af4bb08f" border="0" alt="" />
			</a>
		</iframe>
	</div>
);

// Szeroka reklama 
const AddWide2 = () => (
	<div className="article-adwrapper center">
		<iframe
			id="a3f29d0d"
			name="a3f29d0d"
			src="https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=2&amp;cb=INSERT_RANDOM_NUMBER_HERE"
			frameBorder="0"
			scrolling="no"
			width="1000"
			height="72"
			allow="autoplay"
			title="AddWide2"
		>
			<a
				href="https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=ad48c8ec&amp;cb=INSERT_RANDOM_NUMBER_HERE"
				target="_blank"
			>
				<img
					src="https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=2&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=ad48c8ec"
					border="0"
					alt=""
				/>
			</a>
		</iframe>
	</div>
);


// Reklama w karuzelu Blogów
const AddBlog = () => (
	<div className="article-adwrapper">
        <iframe title="AddBlog" id='a520d14b' name='a520d14b' src='https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=12&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='300' height='90' allow='autoplay'><a href='https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=a6aece24&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=12&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a6aece24' border='0' alt='' /></a></iframe>
	</div>	
);

// Reklama nad nagłówkiem artykułu
const AddArticle = () => (
	<div className="article-adwrapper centerSmall">
        <iframe title="AddArticle" id='ae0259ba' name='ae0259ba' src='https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=8&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='728' allow='autoplay'><a href='https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=a710d4b4&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=8&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a710d4b4' border='0' alt='' /></a></iframe>
	</div>
);

// Reklama w liście artykułów jako ostatni z nich
const AddArticle2 = () => (
	<div className="article-adwrapper article-adwrapper-inline">
        <iframe id='a6fd3211' title="AddArticle2" name='a6fd3211' src='https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=2&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='370' height='435' allow='autoplay'><a href='https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=a2dfa633&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=2&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a2dfa633' border='0' alt='' /></a></iframe>
	</div>
);

// Reklama w sekcji '4-artykułów'
const AddFourArticles = () => (
	<div className="article-adwrapper adwrapper fourArticles">
		<iframe title="AddFourArticles" id='a210eaf2' name='a210eaf2' src='https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='100%' height='auto' allow='autoplay'><a href='https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=acf39415&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img width="100%" height="auto" src='https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=7&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=acf39415' border='0' alt='' /></a></iframe>
	</div>
);

// Alternatywna reklama w sekcji '4-artykułów'
const AddFourArticles2 = () => (
	<div className="article-adwrapper adwrapper fourArticles">
		<iframe title="AddFourArticles2" id='a5a48a09' name='a5a48a09' src='https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=11&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='526' height='165' allow='autoplay'><a href='https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=aa8ed134&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=11&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=aa8ed134' border='0' alt='' /></a></iframe>
	</div>
);

// Reklama nad sekcją polecamy - widok dłuższego artykułu
const AddRecommended = () => (
	<div className="article-adwrapper adwrapper">
		<iframe title="AddRecommended" id='a668947c' name='a668947c' src='https://adserver.ruchmuzyczny.pl/www/delivery/afr.php?zoneid=3&amp;cb=INSERT_RANDOM_NUMBER_HERE' frameBorder='0' scrolling='no' width='300' height='250' allow='autoplay'><a href='https://adserver.ruchmuzyczny.pl/www/delivery/ck.php?n=a09f1666&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='https://adserver.ruchmuzyczny.pl/www/delivery/avw.php?zoneid=3&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a09f1666' border='0' alt='' /></a></iframe>
	</div>
);

export { AddWide1, AddWide2, AddFourArticles, AddFourArticles2, AddBlog, AddArticle, AddArticle2, AddRecommended };