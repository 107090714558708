import React, { useState, useEffect } from 'react'
import {Link} from "react-router-dom";
import '../ArticleList/ArticleList.scss'
import Loader from '../../Components/Loader'
import slug from "slug";

export default function VideoList() {
    
    const name = "VideoList"

    // Articles data storage
    const [data, updateData] = useState(false);

    // Static url to get data from
    const dataUrl = `${window.globalUrl}/video`;

    // Don't map 1st item for desktop
    let nextData = data ? data.slice(1) : [];

    // Get Data
    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
            })
            .catch(err => { throw err });
            document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                if (e.innerHTML.toLowerCase().indexOf("wideo") > -1) {
                    e.classList.add("active")
                    e.parentElement.parentElement.classList.add("first-element")
                } else {
                    e.classList.remove("active")
                    e.parentElement.parentElement.classList.remove("first-element")
                }
            })
            return () => {
                document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                    e.classList.remove("active")
                    e.parentElement.parentElement.classList.remove("first-element")
                })
            }
    }, []);

    let bigImage;
    if (data && data.length > 0 && data[0].image) {
        bigImage = `storage/dharsa-video/${data[0].image}`
    } else {
        bigImage = "storage/placeholder.png";
    }


    return (
        <main>
            {data && data.length > 0 ? (
                <div id="article-list">
                    <div className="main-article">
                        <Link to={`../video/${data[0].id}${data[0].title && data[0].title.length ? '-'+slug(data[0].title) : ''}`}>
                            <div className="image" style={{ backgroundImage: `url(${window.globalUrl}/${bigImage})` }}></div>
                            <div className="title">{data[0].title}</div>
                            <div className="lead">{data[0].excerpt}</div>
                            <div className="author">{data[0].author}</div>
                        </Link>
                    </div>
                    <div className="articles-wrapper">
                        {nextData.map((item, index) => {

                            // Check if image_small exists then check for standard one else set placeholder
                            let image;
                            if (item.image){
                                image = `storage/dharsa-video/${item.image}`
                            } else {
                                image = "storage/placeholder.png";
                            }

                            return (
                                <div key={index} className="article">
                                    <Link to={`/video/${item.id}${item.title && item.title.length ? '-'+slug(item.title) : ''}`}>
                                        <div className="image"
                                            style={{ backgroundImage: `url(${window.globalUrl}/${image})` }}>
                                        </div>
                                        <div>
                                            <div className="title">{item.title}</div>
                                            <div className="lead">{item.excerpt}</div>
                                            <div className="author">{item.author}</div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : <Loader name={name} />
            }
        </main>
    )
}