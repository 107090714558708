import React, {useEffect, useState} from 'react';
import './Newsletter.scss';
import { Link } from "react-router-dom";

import prenumeratImg from '../../assets/img/preneumerate_img.png'

function Newsletter(props) {

    // Create reference to input element
    const inputRef = React.createRef();
    const campaign_tokenRef = React.createRef();
    const start_dayRef = React.createRef();

    const [formState, setFormState] = useState(false)
    const [message, updateMessage] = useState("Dziękujemy za zapis do newslettera!")

    useEffect(() => {
        // return () => {
        //     cleanup
        // }
    }, [formState, message])

    const submitForm = event => {
        event.preventDefault();
        setFormState("loading")
        const formData = new FormData();
        const emailValue = inputRef.current.value;
        const campaign_tokenValue = campaign_tokenRef.current.value;
        const start_dayValue = start_dayRef.current.value;
        formData.append('email', emailValue);
        formData.append('campaign_token', campaign_tokenValue);
        formData.append('start_day', start_dayValue);
        fetch('https://www.newsletter.pwm.info.pl/add_subscriber.html', {
            method: 'POST',
            mode: 'no-cors',
            body: formData
            })
            .then(data => {
                updateMessage("Dziękujemy za zapis do newslettera!")
                setTimeout(() => {
                    setFormState(true)
                }, 1000)
            console.log('Success:', data);
            })
            .catch((error) => {
                updateMessage("Coś poszło nie tak!<br><br>Spróbuj ponownie lub upewnij się, że podany adres e-mail nie znajduje się już w bazie newsletterów.")
                setTimeout(() => {
                    setFormState(true)
                }, 1000)
            console.error('Error:', error);
            });
    }

    return (
        <div className="newsletterWrapper">
            <form id="newsletter" onSubmit={submitForm} acceptCharset="utf-8">
                <div>Newsletter</div>

                {!formState ? (
                    <>
                        <p>Przyłącz się do naszego newslettera i bądź na bieżąco</p>
                        <div>
                            <input ref={campaign_tokenRef} type="hidden" name="campaign_token" value="z" />
                            <input ref={start_dayRef} type="hidden" name="start_day" value="0" />
                            <input ref={inputRef} type="email" name="email" id="" placeholder="Twój adres e-mail" required />
                            <button type="submit">Zapisz się</button>
                        </div>
                        <div className="checkboxes">
                            <label>
                                <input type="checkbox" name="permit1" id="" required />
                                <p>Wyrażam zgodę na otrzymywanie na mój adres e-mail wiadomości zawierających informacje handlowe.</p>
                            </label>
                            <label>
                                <input type="checkbox" name="permit2" id="" required />
                                <p>Oświadczam, że zapoznałem się i akceptuję <a href="https://pwm.com.pl/pl/page/regulamin_newslettera.html">Regulamin Newsletterów</a> oraz <a href="https://pwm.com.pl/pl/page/polityka_prywatnosci.html">Politykę Prywatności.</a></p>
                            </label>
                            <p className="addNote">
                                Administratorem dobrowolnie podanych danych osobowych jest Polskie  Wydawnictwo Muzyczne z siedzibą w Krakowie (31-111) przy Al. Krasińskiego 11A.
                                Twoje dane osobowe będą przetwarzane w celu wysyłki Newslettera zawierającego informacje marketingowe administratora danych.
                                Posiadasz prawo dostępu do treści oraz poprawiania swoich danych  osobowych. Informujemy, iż poza podmiotami uprawnionymi na podstawie przepisów prawa,
                                zebrane dane osobowe nie będą udostępniane.
                            </p>
                        </div>
                    </>
                ) : formState == 'loading' ? (
                    <>
                        <p className="form-message" style={{textAlign: 'center'}}>Trwa zapis do newslettera</p>
                    </>
                ) : (
                    <>
                        <p className="form-message" dangerouslySetInnerHTML={{ __html: message }} />
                    </>
                )}

            </form>
            {!props.preneumerate ?
                <>
                    <div className="separator"/>

                    <div className="prenumerata">
                        <div>Czytaj nas regularnie</div>
                        <p>Najlepsze strony muzyki w prenumeracie</p>
                        <div className="linkWrapper">
                            <Link to={"/preneumerate"}>Zamów teraz</Link>
                        </div>
                        <img src={prenumeratImg} alt={'prenumerat_zdjęcie'}/>
                    </div>
                </>
                : null
            }

        </div>

    );
} 

export default Newsletter;

