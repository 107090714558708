import React from 'react';
import './NewsBanner.scss';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function NewsBanner(props) {
        return (
                <a target="_blank" rel="noopener noreferrer" href={props.url}>
                        <div id="news-banner" style={{ backgroundImage: `url(${props.background})` }}></div>
                </a>
        );
}

export default NewsBanner;

