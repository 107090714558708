import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Loader from '../../Components/Loader'
import {AddRecommended} from '../Ads'
import './RecommendedBox.scss'

function RecommendedBox(props) {
    
    const name = "RecommendedBox"

    // Articles data storage
    const [data, updateData] = useState(false);

    const dataUrl = props.cmsUrl + "/articles-list/popular-articles";

    console.log("RecommendedBox url: ", dataUrl);

    // Get Data
    useEffect(() => {
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
                console.log(name + " successfuly loaded");
            })
            .catch(err => { throw err });
    }, []);


	return (
        data ? (
            <div className="recommended-box">
                <AddRecommended />
                <div className="header">Polecamy</div>
                {data.slice(0,3).map(function(item,index){
                    return(
                        <div className="box" key={index}>
                            <a href={`/article/${item.id}`}>
                                <div className="title">{item.title}</div>
                                <div className="author">{item.author}</div>
                            </a>
                        </div>
                    )
                })}
            </div>
            ) : <Loader name = { name } />
	);
}
  

export default RecommendedBox;
