import React from 'react';
import './Loader.scss'


function Loader(props) {

    const info = props.name ? props.name : "Set name for component"

    console.log("Loading: "+info)

    return (
      <div className="loader"></div>
    );
  }
  
  export default Loader;

