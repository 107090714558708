import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { mobile } from '../../Components/Helpers';
import RecommendedBox from '../RecommendedBox';
import Newsletter from '../Newsletter'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function ArticleContent(props) {
    const cmsUrl = props.url
    const [galleryOpened, toggleGallery] = useState(false)
    const [slideIndex, updateSlideIndex] = useState(1)
    const [text, updateText] = useState()

    // Create reference to carousel element
    const carouselRef = React.createRef();

    const slideChanged = e => updateSlideIndex(e + 1)

    useEffect(() => {
        // Render all images with proper src attribute and description from alt
        document.querySelectorAll(".article-text img").forEach(function (e) {
            const getSrc = e.getAttribute("src");
            const getAlt = e.getAttribute("alt")
            e.setAttribute("src", `${cmsUrl}/${getSrc}`);
            const desc = document.createElement("div");
            desc.classList.add("article-desc");
            desc.innerHTML = getAlt;
            e.parentNode.insertBefore(desc, e.nextSibling);
        })

        // Render 'metric' section
        if (mobile() && props.data.metric && props.data.metric.length > 1) {
            const secondP = document.querySelectorAll(".article-text p")[1];
            const item = document.createElement("div");
            item.classList.add("article-metric", "article-metric-mobile");
            item.innerHTML = props.data.metric.replace(/\uFFFD/g, '');
            secondP.parentNode.insertBefore(item, secondP)
        }

        // Recommended section if article is long enough & paragraph adjustment
        if (document.querySelectorAll(".article-text p").length > 6 && props.data.recommended === "1") {
            const recommendedOrder = props.data.recommended_numb && props.data.recommended_numb < document.querySelectorAll(".article-text p").length ? props.data.recommended_numb : 5
            const sixthP = document.querySelectorAll(".article-text p")[recommendedOrder];
            const item = document.createElement("div")
            item.id = "recommended-box";
            sixthP.parentNode.insertBefore(item, sixthP)
            ReactDOM.render(<RecommendedBox cmsUrl={cmsUrl} />, document.getElementById('recommended-box'));

            // Adjustment if paragraph 6th contains image
            if (document.querySelectorAll(".article-text p")[6]){
                if (document.querySelectorAll(".article-text p")[5].querySelector("img")){
                    document.querySelectorAll(".article-text p")[5].classList.add("flexed-paragraph")
                } 
            }
            // Adjustment if paragraph 6th contains big letter
            if (document.querySelectorAll(".article-text p")[5].querySelector(".excerpt")){
                document.querySelectorAll(".article-text p")[5].classList.add("excerpt-paragraph")
            }
        }

        const galleryBox = document.getElementById("article-gallery");

        if(galleryBox){
            if(props.data.images && props.data.images.length){
                const openBtn = document.createElement("div")
                openBtn.classList.add("open-btn");
                openBtn.innerHTML = "Otwórz";
                openBtn.addEventListener("click", function(){
                    toggleGallery(!galleryOpened)
                })
                galleryBox.appendChild(openBtn);
                const label = document.createElement("p")
                label.classList.add("gallery-label", "iosevka")
                label.innerHTML = props.data.images[0].title
                galleryBox.appendChild(label)
                galleryBox.classList.add("active");
                galleryBox.style.backgroundImage = `url(${props.data.images[0].image})`;
                const galleryLabel = document.querySelector(".gallery-label");
                galleryBox.style.marginBottom = `${galleryLabel.offsetHeight + 40}px`;
                console.log("Label to: ", galleryLabel.offsetHeight)
            } else {
                console.log("Znaleziono kontener na galerię, ale nie dodano zdjęć do artykułu.")
            }
        } else {
            console.log("Nie znaleziono kontenera na galerię. Domyślnie id='article-gallery.'")
        }
    }, [props.data, text])

    useEffect(() => {
    // blockquote for mobile
        console.log(props.data.metric,"props.data.metric")
        console.log(props.data.metric.length > 1,"props.data.metric.length > 1")
        if (mobile() && props.data.metric && props.data.metric.length > 1) {

            let newText = props.data.content;
            if(newText.search("<blockquote>") !== -1) {
                let position = newText.search("<blockquote>") + 12;
                newText = newText.slice(0, position) + "<div class='quotes'></div>" + newText.slice(position);
                updateText(newText);
            }
            //console.log("")
            //console.log(newText,"newText")
        }
    }, [props.data.content, props.data.metric, text])
    

    const articleImage = props.data.data && JSON.parse(props.data.data).image.src.length > 0 ? (
        <div className="article-float-box">
            <img src={props.url + "/" + JSON.parse(props.data.data).image.src} alt={JSON.parse(props.data.data).image.alt} />
            <div className="article-desc">
                {JSON.parse(props.data.data).image.alt}
            </div>
        </div>) : <div></div>;

    let tags = props.data.tags ? props.data.tags.split(",") : false;

    const slides = props.data.images && props.data.images.length ? (
        props.data.images.map(function(item, index){
            return(
                <div key={index} className="article-slide">
                    <div className="slide-image" style={{backgroundImage: `url(${item.image})`}}></div>
                    <h4 className="iosevka">{item.title}</h4>
                </div>
            )
        })
    ) : null

    return (
        <div className="article-content">
            {articleImage}
            
            {/* {articleMetric} */}
            {text
                ? <div className="article-text" dangerouslySetInnerHTML={{ __html: text.replace(/\uFFFD/g, '') }} />
                : <div className="article-text" dangerouslySetInnerHTML={{ __html: props.data.content.replace(/\uFFFD/g, '') }} />
            }

            <Newsletter />
            
            <div className="tags">
                {(props.data.date && props.data.date.length) || (props.data.publication_date && props.data.publication_date.length )
                    ? (
                        <div style={{display: 'inline-block'}}><span>Data: </span><span>{props.data.date || props.data.publication_date}</span></div>
                    ) 
                    : null}
                {props.data.release_id && props.data.release_number && props.data.release_year  ? (
                    <div className="release">
                        <span>Wydanie: </span>
                        <Link target="_blank" to={`/paper-edition/${props.data.release_id}`}>
                            #{props.data.release_number}/{props.data.release_year}
                        </Link>
                    </div>
                ) : null}
            </div>

            {tags ? (
                <div className="tags">
                    <span>TAGI:</span>
                    {tags.map(function(item, index){
                        return(
                            <span key={index}>
                                <Link to={`/tag-list/${item}`}>
                                    {item.replace(/\uFFFD/g, '')}
                                </Link>
                            </span> 
                        )
                    })}
                </div>
            ) : null}

            {props.data.images && props.data.images.length ? (
                <div id="article-gallery-modal" className={galleryOpened ? "opened" : ""}>
                    <div className="carousel-wrapper">
                        <Carousel ref={carouselRef} margin={20} onChange={slideChanged} showThumbs={false} swipeable={true} emulateTouch={true} transitionTime={450} infiniteLoop={true}>
                            {slides}
                        </Carousel>

                        <div className="custom-carousel-controls">
                            <div>
                                <button className="close-gallery iosevka" onClick={() => toggleGallery(!galleryOpened)}>ZAMKNIJ</button>
                            </div>
                            <div></div>
                            <div className="index-wrapper iosevka">
                                {slideIndex}/{props.data.images.length}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default ArticleContent;