import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import './ArticleList.scss'
import Loader from '../../Components/Loader'
import {AddWide1, AddWide2, AddArticle2} from '../../Components/Ads'

export default function BlogList(props) {

    let { id } = useParams();

    let { url } = useRouteMatch();

    const name = "BlogList"

    // Articles data storage
    const [data, updateData] = useState(false);

    // Static url to get data from

    let dataUrl = '';

    id !== undefined
        ? dataUrl = `${props.data}/bloger?category=${id}`
        : dataUrl = `${props.data}/bloger`

    console.log("Blog list url: ", dataUrl);


    // Don't map 1st item for desktop
    let nextData = data ? data.slice(1) : [];


    // Get Data
    useEffect(() => {
        window.scrollTo(0, 0);
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out);
                console.log(name + " successfuly loaded");
                window.category = out[0].category_site;
                console.log("Kategoria to: ", out[0].category_site);
                document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                     // console.log(e);
                    if (e.innerHTML.toLowerCase() === out[0].category_site.toLowerCase()) {
                         e.classList.add("active")
                        e.parentElement.parentElement.classList.add("first-element")
                    } else {
                        e.classList.remove("active")
                        e.parentElement.parentElement.classList.remove("first-element")
                    }
                })
            })
            .catch(err => { throw err });

            return () => {
                document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                    e.classList.remove("active")
                    e.parentElement.parentElement.classList.remove("first-element")
                })
            }
    }, [url]);

    useEffect(() => {
        console.log(name, data)
    }, [data])

    return (
        <main>
            {data && data.length > 0 ? (
                <div id="article-list">
                    <div className="main-article">
                        <Link to={`/blog/${data[0].id}`}>
                            <div className="image" style={{ backgroundImage: `url(${data[0].image})` }}></div>
                            {/* <div className="iosevka m-15-0 center mobile">{data[0].category_site}</div> */}
                            <div className="title">{data[0].title}</div>
                            <div className="lead">{data[0].lead}</div>
                            <div className="author">{data[0].author}</div>
                        </Link>
                    </div>
                    <div className="articles-wrapper">
                        {nextData.map((item, index) => {
                            return (
                                <>
                                    <div key={index} className="article">
                                        <Link to={`/blog/${item.id}`}>
                                            <div className="image"
                                                style={{ backgroundImage: `url(${item.image})` }}>
                                            </div>
                                            <div>
                                                <div className="title">{item.title}</div>
                                                <div className="lead">{item.lead}</div>
                                                <div className="author">{item.author}</div>
                                            </div>
                                        </Link>
                                    </div>
                                    {index === 2 ?  (
                                        <AddWide1 />
                                    ) : null}
                                    {/* {index == 8 ?  (
                                        <AddWide2 />
                                    ) : null} */}
                                </>
                            )
                        }
                        )}
                        <div>
                            <AddArticle2 />
                        </div>
                    </div>
                    {/* <div>
                        <button>Więcej artykułów</button>
                    </div> */}
                </div>
            ) : <Loader name={name} />
            }
        </main>
    )
}
