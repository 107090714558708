import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import "./PaperEdition.scss"
import paperImg from "../../assets/img/okladka-14.jpg"
import paper1 from "../../assets/img/pwm-edition.png"
import paper2 from "../../assets/img/pwm-fb.png"
import paper3 from "../../assets/img/pwm-insta.png"
import paper4 from "../../assets/img/pwm-issu.png"
import { mobile } from '../../Components/Helpers';
import Loader from '../../Components/Loader'
import { minHeight } from '../../Components/Helpers';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../Components/MainSlider/MainSlider.scss';
import './PaperEdition.css'

export default function PaperEditionID(props) {

    window.scrollTo(0, 0);

	let { id } = useParams();
	let { url } = useRouteMatch();
	const name = "PaperEdition"

	// PaperEdition data storage
    const [data, updateData] = useState(false);
    
    const [secData, updateSecData] = useState(false);

	// Static url to get data from
    const dataUrl = `${window.globalUrl}/release/get_by_id/${id}`;
    const secDataUrl = `${window.globalUrl}/release`;
    
    	// Get Data
	useEffect(() => {
		fetch(dataUrl)
			.then(res => res.json())
			.then((out) => {
                updateData(out);
                console.log(out)
			})
			.catch(err => { throw err });
    }, [id, url]);
    
    // Get Secondary Data
	useEffect(() => {
		fetch(secDataUrl)
			.then(res => res.json())
			.then((out) => {
                updateSecData(out);
                // console.log(secData)
			})
			.catch(err => { throw err });
    }, []);

    useEffect(() => {
        console.log(secData)
    }, [secData])
    

    // Create reference to video element
    const carouselRef = React.createRef();

    // Next & Prev carousel buttons
    const prevSlide = () => {
        carouselRef.current.prev()
    }
    const nextSlide = () => {
        carouselRef.current.next()
    }


    // Amount of slides depending on screen width
    const responsive = {
        0: {
            items: 1,
        },
        400: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
        1200: {
            items: 4,
        }
    }

    // console.log(dataUrl);
    
    const carousel = (
        secData ? (
            <div className="paper-carousel-section">
                <h3 className="section-title">Archiwum wydań</h3>
                <div className="carousel-wrapper paper-carousel">
                    <OwlCarousel ref={carouselRef} className="owl-theme" loop margin={10} dots={false} responsive={props.options ? props.options : responsive}>
                        {secData.map(function (item, index) {
                            if(item.id == id){
                                return
                            } else {
                                return (
                                    <Link key={index} to={`/paper-edition/${item.id}`} >
                                        <div className="paper-edition-image" style={{ backgroundImage: `url(${item.image})` }}>
                                            <div className="info-apla">
                                                <div className="number">{item.subtitle}</div>
                                                <div className="year">{item.title}</div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            }
                        })}
                    </OwlCarousel>
                    <button className="carousel-button carousel-prev" onClick={prevSlide}></button>
                    <button className="carousel-button carousel-next" onClick={nextSlide}></button>
                </div>
            </div>
        ) : <Loader name={name} />
    )

    const text = (
        <div>
            {data.category && data.category.length ? (
                data.category.map(function(item,index){
                    if(item.section_view === "1"){
                        void 0
                    } else {
                        return(
                            <section key={index}>
                                <div className="section-title paper-title">{item.name}</div>
                                {item.article && item.article.length ? (
                                    <div className="content-wrapper">
                                        {item.article.map(function(subitem, subindex){
                                            return(
                                                <div key={subindex} className="element">
                                                    <div className="element-title">{subitem.title}</div>
                                                    <div className="element-author iosevka text-right">{subitem.author}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : null}
                            </section>
                        )
                    }
                })
            ) : null}

            <div className="end-section end-section-6">
                {data.category && data.category.length ? (
                    data.category.map(function(item,index){
                        if(item.section_view === "0"){
                            void 0
                        } else {
                            return(
                                <section key={index}>
                                    <div className="section-title paper-title">{item.name}</div>
                                    {item.article && item.article.length ? (
                                        <div className="content-wrapper">
                                            {item.article.map(function(subitem, subindex){
                                                return(
                                                    <div key={subindex} className="element">
                                                        <div className="element-title">{subitem.title}</div>
                                                        <div className="element-author iosevka text-right">{subitem.author}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : null}
                                </section>
                            )
                        }
                    })
                ) : null}
            </div>
            {carousel}
        </div>
    )
   
    return (
        data && secData ? (
            mobile() ? (
                <main className="paper-edition">
                    <div className="fixed-content-wrapper">
                        <div className="content">
                            <div className="top">
                                <img src={data.release.image} alt="Okładka wydania papierowego Ruchu Muzycznego" />
                                <div>
                                    <div className="month">{data.release.subtitle}</div>
                                    <div className="year">{data.release.title}</div>
                                    <div className="more">
                                        <p>Zobacz więcej:</p>
                                        <div>
                                            <a href="https://pwm.com.pl/" target="_blank">
                                                <div style={{ backgroundImage: `url(${paper1})` }}></div>
                                            </a>
                                            <a href="https://www.facebook.com/ruchmuzyczny/" target="_blank">
                                                <div style={{ backgroundImage: `url(${paper2})` }}></div>
                                            </a>
                                            <a href="https://www.instagram.com/ruchmuzyczny/" target="_blank">
                                                <div style={{ backgroundImage: `url(${paper3})` }}></div>
                                            </a>
                                            <div style={{ backgroundImage: `url(${paper4})`, marginTop: '5px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {text}
                        </div>
                    </div>
                </main>
            ) : (
                    <main className="paper-edition">
                        <div className="fixed-content-wrapper">
    
                            <div className="fixed-column">
                                <img src={data.release.image} alt="Okładka wydania papierowego Ruchu Muzycznego" />
                            </div>
                            <div className="content">
                                <div className="top">
                                    <div className="month">{data.release.subtitle}</div>
                                    <div className="year">{data.release.title}</div>
                                    <div className="more">
                                        <p>Zobacz więcej:</p>
                                        <div>
                                            <a href="https://pwm.com.pl/" target="_blank">
                                                <div style={{ backgroundImage: `url(${paper1})` }}></div>
                                            </a>
                                            <a href="https://www.facebook.com/ruchmuzyczny/" target="_blank">
                                                <div style={{ backgroundImage: `url(${paper2})` }}></div>
                                            </a>
                                            <a href="https://www.instagram.com/ruchmuzyczny/" target="_blank">
                                                <div style={{ backgroundImage: `url(${paper3})` }}></div>
                                            </a>
                                            <div style={{ backgroundImage: `url(${paper4})`, backgroundSize: '55px', backgroundPositionY: '4px' }}></div>
                                        </div>
                                    </div>
                                </div>
                                {text}
                            </div>
                        </div>
                    </main>
            )
        ) : <Loader name={name} />
    )
}
