import React from 'react';
import FixedBox from '../../Components/FixedBox'
import './footer.scss';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { mobile } from '../../Components/Helpers';


function footer() {
	return (
		<footer>
			{/* MOBILE VERSION */}
			{mobile() ? (
				<div className="footer-wrapper">
					<div>
						<p>Znajdź nas</p>
					</div>
					<div className="social-wrapper">
						<div>
							<a href="https://www.youtube.com/channel/UC372gqOLYWb8cTt_unqIPqA" target="_blank">
								<button className="footer__button--social footer__button--yt "></button>
							</a>
						</div>
						<div>
							<a href="https://www.facebook.com/ruchmuzyczny/" target="_blank">
								<button className="footer__button--social footer__button--fb "></button>
							</a>
						</div>
						<div>
							<a href="https://www.instagram.com/ruchmuzyczny/" target="_blank">
								<button className="footer__button--social footer__button--in "></button>
							</a>
						</div>
						<div>
							<a href="https://twitter.com/RMuzyczny" target="_blank">
								<button className="footer__button--social footer__button--tw "></button>
							</a>
						</div>
					</div>

					<div>
						<ul>
							<li><Link to="/"><button className="footer__button--home"></button></Link></li>
							<li><Link to="/regulations">Regulamin</Link></li>
							<li><Link to="/declaration">Deklaracja dostępności</Link></li>
							<li><Link to="/privacy-policy">Polityka prywatności</Link></li>
							<li><Link to="/contact">Kontakt</Link></li>
							<li><Link to="/about">O nas</Link></li>
							<li className="lastPoint"><a href="https://pwm.com.pl/" target="_blank">PWM</a></li>
						</ul>
					</div>

					<div>
						<p>© 2020 Polskie Wydawnictwo Muzyczne</p>
					</div>
				</div>

			) : (
					// DESKTOP VERSION
					<div className="footer-wrapper">
						<div>
							<ul>
								<li><Link to="/"><button className="footer__button--home"></button></Link></li>
								<li><Link to="/regulations">Regulamin</Link></li>
								<li><Link to="/declaration">Deklaracja dostępności</Link></li>
								<li><Link to="/privacy-policy">Polityka prywatności</Link></li>
								<li><Link to="/contact">Kontakt</Link></li>
								<li><Link to="/about">O nas</Link></li>
								<li><a href="https://pwm.com.pl/" target="_blank">PWM</a></li>
							</ul>
						</div>
						<div>
							<p>Znajdź nas</p>
							<div className="social-wrapper">
								<div>
									<a href="https://www.youtube.com/channel/UC372gqOLYWb8cTt_unqIPqA" target="_blank">
										<button className="footer__button--social footer__button--yt "></button>
									</a>
								</div>
								<div>
									<a href="https://www.facebook.com/ruchmuzyczny/" target="_blank">
										<button className="footer__button--social footer__button--fb "></button>
									</a>
								</div>
								<div>
									<a href="https://www.instagram.com/ruchmuzyczny/" target="_blank">
										<button className="footer__button--social footer__button--in "></button>
									</a>
								</div>
								<div>
									<a href="https://twitter.com/RuchMuzyczny" target="_blank">
										<button className="footer__button--social footer__button--tw "></button>
									</a>
								</div>
							</div>
							<p>© 2020 Polskie Wydawnictwo Muzyczne</p>
						</div>
					</div>
				)}
			<FixedBox />
		</footer>
	);
}

export default footer;

