import React from 'react'
import './AuthorPage.css'
import authorImg from '../../assets/img/author-1.png'
import FollowButton from '../../Components/FollowButton'

export default function AuthorPage() {
    return (
        <main id="author-page">
            <div className="top-wrapper">
                <div>
                    <img src={authorImg} alt=""/>
                    <FollowButton />
                </div>
                <div>
                    <div className="name">Krzysztof Lipka</div>
                    <div className="origin iosevka">Polskie Radio</div>
                    <div className="description">Koncerty promenadowe wymyślone przez HENRY’EGO WOODA prawie 120 lat temu w Londynie wciąż gromadzą każdego wieczoru kilka tysięcy osób. Poza sprzedawanymi wcześniej biletami uprawniającymi do zajęcia numerowanych miejsc siedzących, codziennie na stoj</div>
                </div>
            </div>
            <div className="articles-wrapper">
                <div className="article">
                    <div className="background" style={{ backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/Ruch_Muzyczny_Pressefoto_Cencic_04.jpg)`}}></div>
                    <div className="title">Dwa odcienie KLASYCYZMU</div>
                    <div className="description">Festiwal Radio Azja w ubiegłych latach za każdym razem mnie zaskakiwał – w 2017 roku ujął mnie zwłaszcza zespół Senyawa oraz Zombie Music</div>
                </div>
                <div className="article">
                    <div className="background" style={{ backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/Ruch_Muzyczny_Pressefoto_Cencic_04.jpg)` }}></div>
                    <div className="title">Dwa odcienie KLASYCYZMU</div>
                    <div className="description">Festiwal Radio Azja w ubiegłych latach za każdym razem mnie zaskakiwał – w 2017 roku ujął mnie zwłaszcza zespół Senyawa oraz Zombie Music</div>
                </div>
                <div className="article">
                    <div className="background" style={{ backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/Ruch_Muzyczny_Pressefoto_Cencic_04.jpg)` }}></div>
                    <div className="title">Dwa odcienie KLASYCYZMU</div>
                    <div className="description">Festiwal Radio Azja w ubiegłych latach za każdym razem mnie zaskakiwał – w 2017 roku ujął mnie zwłaszcza zespół Senyawa oraz Zombie Music</div>
                </div>
                <div className="article">
                    <div className="background" style={{ backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/Ruch_Muzyczny_Pressefoto_Cencic_04.jpg)` }}></div>
                    <div className="title">Dwa odcienie KLASYCYZMU</div>
                    <div className="description">Festiwal Radio Azja w ubiegłych latach za każdym razem mnie zaskakiwał – w 2017 roku ujął mnie zwłaszcza zespół Senyawa oraz Zombie Music</div>
                </div>
                <div className="article">
                    <div className="background" style={{ backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/Ruch_Muzyczny_Pressefoto_Cencic_04.jpg)` }}></div>
                    <div className="title">Dwa odcienie KLASYCYZMU</div>
                    <div className="description">Festiwal Radio Azja w ubiegłych latach za każdym razem mnie zaskakiwał – w 2017 roku ujął mnie zwłaszcza zespół Senyawa oraz Zombie Music</div>
                </div>
                <div className="article">
                    <div className="background" style={{ backgroundImage: `url(https://ruchmuzyczny.pl/cms/storage/Ruch_Muzyczny_Pressefoto_Cencic_04.jpg)` }}></div>
                    <div className="title">Dwa odcienie KLASYCYZMU</div>
                    <div className="description">Festiwal Radio Azja w ubiegłych latach za każdym razem mnie zaskakiwał – w 2017 roku ujął mnie zwłaszcza zespół Senyawa oraz Zombie Music</div>
                </div>
            </div>
        </main>
    )
}
