import React, { useState, useEffect } from 'react';
import {useParams, useRouteMatch } from "react-router-dom";
import ArticleTitle from '../../Components/ArticleTitle';
import AuthorBox from '../../Components/AuthorBox';
import ArticleContent from '../../Components/ArticleContent';
import './Article.scss';
import ArticleActions from '../../Components/ArticleActions';
import { mobile } from '../../Components/Helpers';
import Loader from '../../Components/Loader'

function Article(props) {

	window.scrollTo(0, 0);

	let { id } = useParams();
	id = parseInt(id);
	let { url } = useRouteMatch();

	const name = "Article"

	// Articles data storage
	const [data, updateData] = useState(false);
	const mainUrl = props.data

	// Static url to get data from
	const dataUrl = `${props.data}/articles-list/article/${id}`;

	// Get Data
	useEffect(() => {
		fetch(dataUrl)
			.then(res => res.json())
			.then((out) => {
				updateData(out[0]);
				console.log(out[0])
				window.category = out[0].category;
				document.querySelectorAll("#main-menu .menu-item span").forEach(function(e){
					if (e.innerHTML.toLowerCase() == out[0].category.toLowerCase()){
						e.classList.add("active")
						e.parentElement.parentElement.classList.add("first-element")
					} else {
						e.classList.remove("active")
						e.parentElement.parentElement.classList.remove("first-element")
					}
				})
			})
			.catch(err => { throw err });

			return () => {
				document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
					e.classList.remove("active")
					e.parentElement.parentElement.classList.remove("first-element")
				})
			}
	}, [url]);

	/*smooth scroll dla hrefów z odnośnika*/

	useEffect(() => {
		const selectors = document.getElementsByTagName('a')
		const firstPart = window.location.protocol + "//" + window.location.host + window.location.pathname;

		Array.from(selectors).forEach((selector)=> {
			let href = selector.href.replace(firstPart, "")

			if(href.slice(0,1) === "#") {
				href = href.slice(1,href.length)

				selector.addEventListener("click" ,(e) => {
					e.preventDefault();

					console.log(document.getElementsByName(href))
					document.getElementsByName(href)[0].scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				})
			}
		})
	})

	const articleMetric = data && data.metric && data.metric.length > 1 ? <div className="article-metric" dangerouslySetInnerHTML={{ __html: data.metric }} /> : null

	return (
		data ? (
			<main>
				{mobile() ? (
					<section id="article-view">
						<div className="article-header-wrapper">
							<div>
								<ArticleTitle data={data} />
								<div className="article-box-1">
									<AuthorBox data={data} />
								</div>
								<div className="article-box-2">
									<ArticleActions data={data} url={mainUrl} />
								</div>
							</div>
						</div>
						<div className="article-content-wrapper">
							<div>
								<ArticleContent data={data} url={mainUrl} />
							</div>
						</div>
					</section>
				) : (
					<section id="article-view">
						<div className="article-header-wrapper">
							<div>
							</div>
							<div>
								<ArticleTitle data={data} />
								<AuthorBox data={data} />
							</div>
						</div>
						<div className="article-content-wrapper">
							<div className={`article-options ${data && data.metric && data.metric.length > 1 ? "" : "no-metric"}`}>
								<ArticleActions data={data} url={mainUrl} />
								{articleMetric}
							</div>
							<div>
								<ArticleContent data={data} url={mainUrl} />
							</div>
						</div>
					</section>
					)
				}
			</main>
		) : <Loader name={name} />
	);
}
export default Article;