import React from 'react';
import './VideoText.scss';

function VideoText(props) {
    return (
        <div className="video-text">
            <div className="title">{props.data.title}</div>
            <div className="lead">{props.data.description}</div>
            <div className="author">{props.data.author}</div>
        </div>
    );
}

export default VideoText;

