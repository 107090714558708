import React from 'react';
import MainNews from '../../Components/MainNews';
import MainSlider from '../../Components/MainSlider';
import VideoSection from '../../Components/VideoSection';
import BlogSlider from '../../Components/BlogSlider';
import FourArticles from '../../Components/FourArticles';
import Anniversary from '../../Components/Anniversary';
import Podcasts from '../../Components/Podcasts';
import {AddWide1} from '../../Components/Ads';
import Newsletter from '../../Components/Newsletter';


function main(props) {

	window.scrollTo(0, 0);

	let data = props.data;

	return (
		<main>
			<MainNews data={data} />
			<section className="addwrapper center" style={{paddingTop: 55, paddingBottom: 10}}>
        		<AddWide1 />
			</section>
			<MainSlider type="news" mainUrl={data} title="Aktualności" limit="20" />
			{/* <Anniversary/> */}
			<FourArticles data={data} type="movement_area" />
			<MainSlider type="relations" mainUrl={data} title="Relacje" limit="20" />
			<VideoSection data={data} />
			{/* <MainSlider type="bloger" mainUrl={data} title="Blogi" /> */}
			{/* <Podcasts /> */}
			
			{/* <MainSlider data={`${data}/articles-list/category/news`} mainUrl={data} title="Aktualności" /> */}
			{/*<BlogSlider title={"Blogi"}/>*/}
			<Newsletter/>
		</main>
	);
}

export default main;

