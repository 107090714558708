import React, { useState, useEffect } from 'react';
import { useHistory, BrowserRouter as Router, Link, useParams } from "react-router-dom";
import Loader from '../../Components/Loader';
import {minHeight, mobile} from '../../Components/Helpers';
import './SearchList.css'

export default function SearchList() {

    window.globalSearch ? window.globalSearch = window.globalSearch : window.globalSearch = ""

    let history = useHistory()

    const limit = 15;

    const name = "SearchList"
    const [data, updateData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [counter, updateCounter] = useState(0);
    const [noMore, setNoMore] = useState(false);

    const dataUrl = `${window.globalUrl}/articles-search?limit=${limit}&offset=${limit * counter}`;

    // Get Data
    useEffect(() => {
        const formData = new FormData();
        formData.append('search', window.globalSearch ? window.globalSearch : window.location.pathname.split("/")[2]);
        window.scrollTo(0, 0);
        fetch(dataUrl, {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then((out) => {
                updateData(out);
                setLoading(false);
            })
            .catch(err => { throw err });
    }, [window.globalSearch]);

    useEffect(() => {
        if (counter > 0 && !noMore){
            setLoading(true)
            const formData = new FormData();
            formData.append('search', window.globalSearch ? window.globalSearch : window.location.pathname.split("/")[2]);
            fetch(dataUrl, {
                method: 'POST',
                body: formData,
            })
                .then(res => res.json())
                .then((out) => {
                    console.log(out.length)
                    console.log(limit)
                    if (out.length + 1 <= limit) {
                        setNoMore(true);
                    }
                    updateData(data.concat(out.slice(0, limit)));
                })
                .then(setLoading(false))
                .catch(err => { throw err });
        }
    }, [counter])

    // Create reference to input element
    const inputRef = React.createRef();

    const searchHandler = (e) => {
        e.preventDefault();
        if(inputRef.current.value) {
            if(history.location.pathname.slice(13) !== inputRef.current.value) {
                setLoading(true);
                window.globalSearch = inputRef.current.value;
                history.push({ pathname: `/search-list/${inputRef.current.value}`})
            }
        }
    }

    const searchToggleHandlerMobile = () => {
        /*searchOpen ? (
            inputRef.current.setAttribute("placeholder", "")
        ) : (
            inputRef.current.setAttribute("placeholder", "Szukaj...")
        )
        inputRef.current.value = ""*/
    }

    if(document.querySelector("main")){
        minHeight()
    }

    return (
        data && !loading ? (
            <main id="search-list">
                <div className="search-input-wrapper">
                    <div className={"search-area"}>
                        <form onSubmit={searchHandler}>
                            <input ref={inputRef} className="iosevka input-padding" type="text" placeholder={`Wyniki wyszukiwania: ${window.globalSearch}`} />
                            {mobile() ? (
                                <button className="search-button" onClick={searchHandler} ></button>
                            ) : null}
                        </form>
                    </div>
                </div>
                <div className="search-list">
                    {data && data.length > 0 ? (
                        data.map(function(item,index){
                            // Check if image_small exists then check for standard one else set placeholder
                            let image;
                            const itemData = JSON.parse(item.data);
                            if (itemData.image_small.src.length > 0) {
                                image = itemData.image_small.src;
                            } else {
                                image = "storage/placeholder.png";
                            }

                            return(
                                <Link key={index} to={`/article/${item.id}`}>
                                    <div className="search-item">
                                        <div className="text-wrapper">
                                            <div className="title">{item.title}</div>
                                            <div className="desc iosevka">{item.author}</div>
                                        </div>
                                        <div className="background" style={{ backgroundImage: `url(${window.globalUrl}/${image})` }}></div>
                                    </div>
                                </Link>
                            )
                        })
                    ) : <div className="title">Brak wyników</div>}
                    {noMore ? null : <button id="more-articles-search" onClick={() => updateCounter(counter+1)}><span>Więcej artykułów</span></button>}
                </div>
            </main>
        ) : <Loader name={name} />
    )
}