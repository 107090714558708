import React, { useState, useEffect } from 'react'
import { Link, useParams, useRouteMatch } from "react-router-dom";
import './ArticleList.scss'
import Loader from '../../Components/Loader'
import LoaderBox from '../../Components/LoaderBox'
import {AddWide1, AddArticle, AddArticle2} from '../../Components/Ads'
import clamp from "clamp-js"
import slug from "slug";

export default function ArticleList(props) {

    let { id } = useParams();

    let { url } = useRouteMatch();

    const articlesWrapper = React.createRef();

    const name = "ArticleList";

    const limit = 18;

    // Articles data storage
    const [data, updateData] = useState(false);
    const [counter, updateCounter] = useState(0);
    const [noMore, setNoMore] = useState(false);
    const [loading, setLoading] = useState(false);

    // Static url to get data from
    let dataUrl = `${props.data}/articles-list/category/${id}?limit=${limit+1}&offset=${limit * counter}`;

    // Don't map 1st item for desktop
    let nextData = data ? data.slice(1) : [];


    // Get Data
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(dataUrl);
        fetch(dataUrl)
            .then(res => res.json())
            .then((out) => {
                updateData(out.slice(0, limit));
                window.category = out[0].category;
                if (out.length <= limit) {
                    setNoMore(true);
                }
                document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                    if (e.innerHTML.toLowerCase() == out[0].category.toLowerCase()) {
                        e.classList.add("active")
                        e.parentElement.parentElement.classList.add("first-element")
                    } else {
                        e.classList.remove("active")
                        e.parentElement.parentElement.classList.remove("first-element")
                    }
                })
            })
            .catch(err => { throw err });

            return () => {
                document.querySelectorAll("#main-menu .menu-item span").forEach(function (e) {
                    e.classList.remove("active")
                    e.parentElement.parentElement.classList.remove("first-element")
                })
            }
    }, [url]);

    useEffect(() => {
        if (counter > 0 && !noMore){
            setLoading(true)
            fetch(dataUrl)
                .then(res => res.json())
                .then((out) => {
                    if (out.length <= limit) {
                        setNoMore(true);
                    }
                    updateData(data.concat(out.slice(0, limit)));
                })
                .then(setLoading(false))
                .catch(err => { throw err });
        }
    }, [counter])

    useEffect(() => {
        if (noMore){

        }
    }, [noMore])

    let bigImage, bigImageItem;

    if (data && data.length > 0) {
        bigImageItem = JSON.parse(data[0].data)
        if (bigImageItem.image.src.length > 0) {
            bigImage = bigImageItem.image.src;
        } else if (bigImageItem.image_small.src.length > 0) {
            bigImage = bigImageItem.image_small.src;
        } else {
            bigImage = "storage/placeholder.png";
        }
    }

    useEffect(() => {
        const elements = document.getElementsByClassName("leadArticle")

        Array.from(elements).forEach((element) => {
            clamp(element, {clamp: 3})
        })
    },[data])


    return (
        <main>
            {data && data.length > 0 ? (
                <div id="article-list">
                    <div className="main-article">
                        <Link to={`/article/${data[0].id}${data[0].title && data[0].title.length ? '-'+slug(data[0].title) : ''}`}>
                            <div className="image" style={{ backgroundImage: `url(${props.data}/${bigImage})` }}></div>
                            <div className="iosevka m-15-0 center mobile">{data[0].category}</div>
                            <div className="title">{data[0].title}</div>
                            <div className="lead">{data[0].excerpt}</div>
                            <div className="author">{data[0].author}</div>
                        </Link>
                    </div>
                    <div ref={articlesWrapper} className="articles-wrapper">
                        {nextData.map((item, index) => {
                            // Check if image_small exists then check for standard one else set placeholder
                            let image;
                            const itemData = JSON.parse(item.data);
                            if (itemData.image_small.src.length > 0) {
                                image = itemData.image_small.src;
                            } else if (itemData.image.src.length > 0) {
                                image = itemData.image.src;
                            } else {
                                image = "storage/placeholder.png";
                            }

                            return (
                                <>
                                    <div key={index} className="article">
                                        <Link to={`../article/${item.id}${item.title && item.title.length ? '-'+slug(item.title) : ''}`}>
                                            <div className="image"
                                                style={{ backgroundImage: `url(${props.data}/${image})` }}>
                                            </div>
                                            <div>
                                                <div className="title">{item.title}</div>
                                                <div className="lead leadArticle">{item.excerpt}</div>
                                                <div className="author">{item.author}</div>
                                            </div>
                                        </Link>
                                    </div>
                                    {index == 2 ?  (
                                        <AddWide1 />
                                    ) : null}
                                    {index == 8 ?  (
                                        <AddArticle />
                                    ) : null}
                                </>
                            )
                        })}
                        <AddArticle2 />
                    </div>
                    {loading ? <LoaderBox /> : null}
                    {noMore ? null : <button id="more-articles" onClick={() => updateCounter(counter+1)}><span>Więcej artykułów</span></button>}
                </div>
            ) : <Loader name={name} />
            }
        </main>
    )
}