import React from 'react';
import Newsletter from '../../Components/Newsletter';

import './NewsletterSite.scss';

function NewsletterSite() {

    return (
        <main id={"NewsletterSite"}>
            <Newsletter preneumerate={true}/>
        </main>
    );
}

export default NewsletterSite;
