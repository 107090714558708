import React from 'react';
import './FixedBox.scss';

function FixedBox() {

    const alreadyClosed = localStorage.getItem("alreadyClosed");
    const displayed = alreadyClosed ? "removed" : null;
    const closeMessage = () => {
        document.getElementById("message-box").classList.add("fadeOut");
        localStorage.setItem("alreadyClosed", true);
        setTimeout(()=>{
            document.getElementById("message-box").remove();
        }, 1000);
    }

	return (
        <div id="message-box" className={displayed}>
            <div>
                <button id="close-message-box" onClick={closeMessage}>+</button>
                <div>
                    Witamy na nowej stronie Ruchu Muzycznego.
                    Cały czas wypełniamy ją nowymi treściami i funkcjonalnościami.
                    Każda kolejna wizyta może okazać się miłym zaskoczeniem.
                </div>
            </div>
        </div>
	);
  }
  
  export default FixedBox;

