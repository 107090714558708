import React, { useState, useEffect } from 'react';
import './header.scss';
import Logo from '../../assets/logo.svg'
import MainMenu from '../../Components/MainMenu/MainMenu'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


export default function Header() {

	useEffect(()=>{
		// console.log("HEADER: ", window.location.href);
		openToggle(true)
	}, [window.location.href])


	const [scrollPos, scrollUpdate] = useState(0);
	const [isOpen, openToggle] = useState(true);

	window.addEventListener("scroll", function () {
		var st = window.pageYOffset;
		if (st > scrollPos && window.pageYOffset > 300) {
			/*console.log("down");*/
			if(document.getElementById("main-menu").classList.contains("opened")) {
				openToggle(true)
				/*.style.overflow = "hidden"*/
			} else {
				openToggle(false)
			}
		} else if (st < scrollPos && window.pageYOffset > 300) {
			/*console.log("up")*/
			openToggle(true)
		}
		scrollUpdate(st);


	}, false);


	

	return (
		<header className={isOpen ? "opened" : "closed"}>
			<div className="menu-wrapper">
				<Link to="/">
					<div className="main-logo">
						<img src={Logo} alt="" />
						<h1>Ruch Muzyczny</h1>
					</div>
				</Link>
				<MainMenu></MainMenu>
			</div>

		</header>
	)
}


